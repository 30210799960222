import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { useLessonList } from './fetchdata';
import { useGptForm } from './mutation';
import SelectPage from './select';
import { LoadingIcon } from '../../components/loader';
import { useGptFicheForm } from './full/hook';
import { courses, domains } from '../../utils/lessons';
import { textesFrancais } from '../../utils/textes';
import { Ressource, useRessourceListMutation, useRessourceListQuery } from '../ressources/form/hook';
import { useDebounce } from "use-debounce";
import mailToLink from 'mailto-link';
import { set } from 'react-hook-form';
import { RessourceFormModal } from '../ressources/form';
import { getCoursesFromOptionValue, getOptionAndSection } from '../../utils/sections';

interface CreateSituationPageProps {
  result?: string;
  title?: string;
  isFiche?: boolean;
}

const CreateSituationPage: FC<CreateSituationPageProps> = (props) => {

  const state = useSelector((state: any) => state);
  const token = state.appState.token;
  const lessonData = useLessonList(token);
  const [data, setData] = useState({} as any);
  const getClasses = () => {
    const classes = [];
    const primaire = ['1', '2', '3', '4', '5', '6'];
    const ctb = ['7', '8'];
    const secondaire = ['1', '2', '3', '4'];
    const maternel = ['1', '2', '3'];
    const level =
      data.level === 'primaire'
        ? primaire
        : data.level === 'ctb'
        ? ctb
        : data.level === 'maternelle' ? maternel
        : secondaire;
    const adding = data.level === 'secondaire' || data.level === 'maternelle';
    const suffix = data.level === 'maternelle' ? 4 : 0;
    for (let i = 0; i < level.length; i++) {
      classes.push({
        label: `${level[i]} ${data.level}`,
        value: `${adding ? Number.parseInt(level[i]) + 8 + suffix : Number.parseInt(level[i])}`,
      });
    }
    return classes;
  };

  console.log(data.subject);

  const [texts, setTexts] = useState<{data: Ressource[]; total: number}>({data: [], total: 0});
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);

  
  console.log(texts, 'textssnakjn')

  const mutationText = useRessourceListMutation(setTexts);
  
  useEffect(() => {
    console.log(data.subject, 'changed');
    mutationText.mutate({limit: 20, page: 1, subject: data.subject, search: debouncedSearch});
  }, [data.subject, debouncedSearch]);

  const [showRessource, setShowRessource] = useState(false);

  const questions = [
    {
      name: 'level',
      label: 'Vous enseignez à quel niveau ?',
      type: 'select',
      options: [
        {label: 'Maternelle', value: 'maternelle'},
        {label: 'Primaire', value: 'primaire'},
        {label: 'CTB', value: 'ctb'},
        {label: 'Secondaire', value: 'secondaire'},
      ],
    },
    {
      name: 'section',
      label: 'Vous enseignez dans quelle section / option ?',
      type: 'select',
      creatable: true,
      options: getOptionAndSection()
    },
    {
      name: 'class',
      label: 'Vous enseignez dans quelle classe ?',
      type: 'select',
      options: getClasses(),
    },
    {
      name: 'subject',
      label: 'Vous voulez vous faire assister pour quel cours ?',
      type: 'select',
      inputTextName: 'subjectAlternative',
      alternativeLabel: 'Ecrivez le nom du cours',
      creatable: true,
      description: 'Vous pouvez ecrire le nom du cours si vous ne le trouvez pas dans la liste',
      options: [
        ...getCoursesFromOptionValue(data.section).map(v => ({label: v, value: v})),
        ...[
        {label: 'Exploratoire', level: ['maternelle'], subdomain: ["Decouverte de l'environnement", 'Eveil scientifique']},
        {label: 'Language', level: ['maternelle'], subdomain: ['Recitation, Conte, Saynette', 'Pre-lecture', 'Elocution']},
        {label: 'Mathematiques', level: ['maternelle'], subdomain: ['Nombre & Sens des operations']},
        {label: 'Sensorielle', level: ['maternelle'], subdomain: ['Excercice tactil', 'Exercice visuel', 'Excercice olfactif', 'Exercice gustatif', 'Exercice auditif', 'Exercice kinesthesique']},
        {label: 'Vie pratique', level: ['maternelle'], subdomain: ['Jeux de metier', 'Jeux menager']},
        {label: 'Comportement', level: ['maternelle'], subdomain: ['Education civique', 'Education morale', 'Education Religieuse']},
        {label: 'Promotion de la sante', level: ['maternelle'], subdomain: ['Education a la sante', "Education a l'hygienne", 'Education a la nutrition']},
        {label: 'Musicale', level: ['maternelle'], subdomain: ['Chant', 'Orchestration', 'Ronde']},
        {label: 'Physique', level: ['maternelle'], subdomain: ['Gymnastique']},
        {label: 'Psychomotrice', level: ['maternelle'], subdomain: ['Schema corporel', 'Structuration spatiale', 'Orientation temporelle', 'Lateral']},
        {label: 'Art plastiques', level: ['maternelle'], subdomain: ['Graphique', 'Manuel']},
        {label: 'Libre (Jeux dans les coins)', level: ['maternelle'], subdomain: ['Chambre', 'Bureau', 'Garage', 'Cuisine']},
        
        {label: 'Anatomie', level: ['primaire', 'ctb']},
        {label: 'Anglais', level: ['maternelle','primaire', 'ctb', 'secondaire']},
        {label: 'Biologie', level: ['secondaire']},
        {label: 'Botanique', level: ['primaire', 'ctb']},
        {label: 'Chimie', level: ['secondaire']},
        {label: 'Comptabilité', level: ['secondaire']},
        {label: 'Dessin', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Économie', level: ['secondaire']},
        {label: 'Education A la vie', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Éducation civique', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Éducation physique', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Éducation religieuse', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Études environnementales', value: 'Études environnementales'},
        {label: 'Études sociales', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Français', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Conjugaison', 'Grammaire', 'Litterature', 'Orthographe', 'Phonétique', 'Redaction', 'Vocabulaire']},
        {label: 'Géographie', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Histoire', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Informatique', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Infographie', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Design - Communication visuelle', 'Design - Graphisme']},
        {label: 'Kiswahili', level: ['primaire'], subdomain: ['Usemi', 'Usomi']},
        {label: 'Leadership', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Mathématiques', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Algèbre', 'Analyse', 'Arithmétique', 'Géométrie', 'Logique', 'Statistiques', 'Probabilités']},
        {label: 'Musique', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'organisation des entreprises', level: ['secondaire']},
        {label: 'Physique', level: ['ctb', 'secondaire']},
        {label: 'Sciences Physique', level: ['primaire', 'ctb', 'secondaire']},
        {label: 'Secourisme', level: ['maternelle','primaire', 'ctb', 'secondaire']},
        {label: 'Zoologie', level: ['primaire', 'ctb', 'secondaire']},
      ].filter(course => course?.level?.includes(data?.level)).sort((a, b) => a.label.localeCompare(b.label)).map(course => ({label: course.label, value: course.label}))],
    },
    {
      name: 'domain',
      label: 'Veillez choisir ou ecrire le sous domaine',
      type: 'select',
      creatable: true,
      inputTextName: 'domain',
      alternativeLabel: 'Ecrivez le sous domaine',
      optionnal: true,
      description: 'Optionnel ( vous pouvez ecrire le sous domaine si vous ne le trouvez pas dans la liste)',
      options: [...(domains.filter(domain => domain.courses.includes(data.subject?.toLowerCase?.()))), ...(courses.find(course => course.label === data.subject)?.subdomain?.map(s => ({label: s, value: s})) || [])],
    },
    {
      name: 'lessonTitle',
      label: 'Quel est le titre de votre leçon ?',
      type: (lessonData?.data || [])?.filter(
        lesson =>
          lesson.subject?.toLowerCase?.() === data.subject?.toLowerCase?.(),
      )?.length ? 'select' : 'textarea',
      creatable: true,
      alternativeLabel: 'Ecrivez le titre de la leçon',
      options: [
        ...(lessonData?.data || [])
          .filter(
            lesson =>
              lesson.subject?.toLowerCase?.() === data.subject?.toLowerCase?.(),
          )
          .map(lesson => ({
            label: lesson.title,
            value: lesson.title,
          })),
      ],
    },
    {
      name: 'previousLesson',
      label: 'Quel est le titre de votre leçon de revision ?',
      type: (lessonData?.data || [])
      .filter(
        lesson =>
          lesson.subject?.toLowerCase?.() === data.subject?.toLowerCase?.(),
      )?.length ? 'select' :  'textarea',
      creatable: true,
      alternativeLabel: 'Ecrivez le titre de la leçon',
      options: [
        ...(lessonData?.data || [])
          .filter(
            lesson =>
              lesson.subject?.toLowerCase?.() === data.subject?.toLowerCase?.(),
          )
          .map(lesson => ({
            label: lesson.title,
            value: lesson.title,
          })),
      ],
    },
    {
      name: 'contentOutline',
      label: 'Quelles sont les points à inclure dans votre leçon ?',
      type: 'textarea',
      creatable: true,
      alternativeLabel: 'Ecrivez le titre de la leçon',
      optionnal: true,
      description: 'Optionnel (Nous voulons limiter le contenu de la  fiches aux informations pertinentes)',
      placeholder: `Ex : - Deifinition 
      - Caracteristique
      - Etc`
    },
    {
      name: 'areaOfLife',
      label: 'Dans quel domaine de la vie vous voulez qu’on tire les examples',
      inputTextName: 'areaOfLifeAlternative',
      type: 'select',
      creatable: true,
      alternativeLabel: 'Ecrivez le domaine de la vie',
      description: 'Nous voulons que les exemples soient pertinents pour vos élèves',
      options: [
        {label: 'Agriculture', value: 'agriculture'},
        {label: 'Commerce', value: 'commerce'},
        {label: 'Education', value: 'education'},
        {label: 'Santé', value: 'health'},
        {label: 'Securité', value: 'security'},
        {label: 'Social', value: 'social'},
        {label: 'Sport', value: 'sport'},
        {label: 'Technologie', value: 'technology'},
        {label: 'Construction', value: 'construction'},
        {label: 'Art', value: 'art'},
        {label: 'Science', value: 'science'},
        {label: 'Transport', value: 'transport'},
        {label: 'Communication', value: 'communication'},
        {label: 'Environnement', value: 'environment'},
        {label: 'Energie', value: 'energy'},
        {label: 'Finance', value: 'finance'},
        {label: 'Cuisine', value: 'cuisine'},
        {label: 'Mode', value: 'mode'},
        {label: 'Tourisme', value: 'tourism'},
        {label: 'Droit', value: 'law'},
        {label: 'Politique', value: 'politics'},
        {label: 'Religion', value: 'religion'},
        {label: 'Culture', value: 'culture'},
        {label: 'Histoire', value: 'history'},
      ].sort((a, b) => a.label.localeCompare(b.label)),
    },
    {
      name: 'texteId',
      label: 'Quel support ou texte voulez-vous utiliser ?',
      type: 'select',
      description: 'Optionnel',
      // options: ['french', 'francais', 'français'].includes(data.subject?.toLowerCase?.()) ? textesFrancais.map(text => ({label: text.title, value: text.text})) : ['english', 'anglais'].includes(data.subject?.toLowerCase?.()) ? textesAnglais.map(text => ({label: text.title, value: text.text})) : [],
      options: texts?.data?.map(text => ({label: text.title, value: text.id})) || [],
      skip: () => {
        const result = !['french', 'francais', 'français', 'english', 'anglais'].includes(data.subject?.toLowerCase?.());
        return result;
      },
      onSearch: (search: string) => {
        setSearch(search);
      },
      optionnal: true,
      alternativeLabel: 'Demander l\'ajout d\'un texte',
      noticeText: "Vous voyez pas votre texte ? Demandez son ajout ici !",
      isLoading: mutationText.isLoading,
      selectLabel: 'Ecrivez le titre , puis selectionnez',
      noticeLink: mailToLink({
        subject: 'Demande d\'ajout d\'un texte',
        body: `Bonjour, je voudrais que vous ajoutiez un texte sur le cours de (d') ${data.subject} qui s'appelle ${debouncedSearch}`,
        to: 'admin@dugassistant.com'
      }),
      noticeAction: () => {
        console.log('data')
        setShowRessource(true);
      },
      noticeTextAction: 'Ou ajoutez le vous même ici',
    },
    {
      name: 'imagePrompt',
      label: "Veuillez decrire votre image, precisez les details que vous souhaitez voir sur l'image.",
      type: 'textarea',
      description: 'Optionnel',
      options: [],
      onSearch: (search: string) => {
        setSearch(search);
      },
      optionnal: true,
      isLoading: mutationText.isLoading,
      selectLabel: "Decrivez l'image",
    }
  ];



  const [result, setResult] = useState('')
  const [isFiche, setIsFiche] = useState(props.isFiche);

  const onSituationgenerated = (data: any) => {
    setResult(data);
  };

  const mutation = useGptForm(onSituationgenerated, token);
  const mutationFiche = useGptFicheForm((data: any) => {
    // console.log(data)
    setResult(data);
  }, token);

  const [step, setStep] = useState(0);
  const [prev, setPrev] = useState(false);

  const setValue = (value: any, name: string, next = true, prev = false, force = false) => {
    if(prev && step > 0) {
      setPrev(true);
      setStep(step - 1);
      return;
    }
    const datas = {...data};
    datas[name] = value;
    setData({...datas});

    if (next && (value || force)) {
      let isDone = false;

      if(step === questions.length - 2 && !isFiche) isDone= true;
      if(step === questions.length - 1 && isFiche) isDone = true;

      if (isDone) {
        if(isFiche) {
          mutationFiche.mutate({...data, [name]: value, isFiche});
        } else {
          mutation.mutate({...data, [name]: value, isFiche});
        }
        return;
      }
      setStep(step + 1);
    }
  };

  const retry = () => {
    if(props.isFiche) mutationFiche.mutate(data);
    else mutation.mutate(data);
  };

  const isLoading = mutation.isLoading || lessonData.isLoading || mutationFiche.isLoading;

  useEffect(() => {
    // if (questions[step].name === 'texteId' && !['french', 'francais', 'français', 'english', 'anglais', 'language'].includes(data.subject?.toLowerCase?.())) {
    //   setValue('', 'texteId', true, false, true);
    // } 
    // else if(!prev && questions[step].name === 'domain' && (!['french', 'Mathématiques', 'francais', 'français', 'english', 'anglais', 'language'].includes(data.subject?.toLowerCase?.())) && !courses.find(course => course.label === data.subject)?.subdomain?.length) {
    //   setValue('', 'domain', true, false, true);
    // } 
    if(questions[step].name === 'section' && !['secondaire'].includes(data.level)) {
      setValue('', 'section', true, false, true);
    }
    setPrev(false);
  }, [step]);

  return (
    <div className={`md:w-[450px] sm:w-[100%] ${(result && !isLoading) ? 'md:w-[600px]' : ''}`}>
      {(isLoading || result) && <div className=" bg-secondary50 bg-opacity-95 fixed w-full h-full z-50 text-lg text-left">
            <p className="font-bold">Veuillez patienter, nous traitons votre demande...</p>
            <div className="ml-[10px] mt-10"><LoadingIcon height={'h-[24px] w-[24px]'} /> </div>
          </div>
      }
      {showRessource && <RessourceFormModal onSuccess={(id) => {
        setValue(id, 'texteId', true, false, true);
        setShowRessource(false);
      }} 
      onClose={() => setShowRessource(false)}
      course={data.subject} />}
      {
        (!result && !props.result) &&
        questions.map((question, index) => {
          if(index === step) {
          return (
            <SelectPage
              key={`${index}-step`}
              setValue={setValue}
              {...question}
              data={data}
              show={step === index}
              setIsFiche={setIsFiche}
              isFiche={isFiche}
            />
          );
          } else {
            return <div></div>
          }
        })}
    </div>
  );
};

export default CreateSituationPage;
