import React, { useState } from 'react';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { LoadingIcon } from './loader';
import { Feedback } from '../types/feedback';

interface FeedbackDialogProps {
  isOpen: boolean;
  onClose: () => void;
  ficheId: string;
  initialPhoneNumber?: string;
}

export const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ 
  isOpen, 
  onClose, 
  ficheId,
  initialPhoneNumber 
}) => {
  const [formData, setFormData] = useState<Partial<Feedback>>({
    ficheId,
    rating: 5,
    content: '',
    whatsappNumber: initialPhoneNumber || ''
  });

  const queryClient = useQueryClient();
  const createFeedback = useMutation({
    mutationFn: async (data: Partial<Feedback>) => {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/feedback`, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['feedback', ficheId] });
      onClose();
    }
  });

  const handleSubmit = async () => {
    await createFeedback.mutateAsync(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Feedback Form"
      className="w-[95vw] md:w-[600px] bg-white mx-auto rounded-3xl shadow-lg flex flex-col justify-between z-[200]"
      style={{
        content: {
          width: window.innerWidth <= 768 ? '95vw' : '600px',
          height: 'auto',
          margin: 'auto',
          padding: window.innerWidth <= 768 ? '20px' : '30px',
          borderRadius: '24px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1000,
        }
      }}
    >
      <div className="space-y-4 md:space-y-6">
        <h2 className="text-lg md:text-2xl font-bold text-center">Votre avis nous intéresse</h2>
        
        <div className="space-y-3 md:space-y-4">
          <div>
            <label className="block text-sm md:text-base font-medium mb-2">Note (1-10)</label>
            <input
              type="range"
              min="1"
              max="10"
              value={formData.rating}
              onChange={(e) => setFormData(prev => ({ ...prev, rating: parseInt(e.target.value) }))}
              className="w-full"
            />
            <div className="text-center text-sm md:text-base font-medium">{formData.rating}/10</div>
          </div>

          <div>
            <label className="block text-sm md:text-base font-medium mb-2">Commentaire (optionnel)</label>
            <textarea
              value={formData.content}
              onChange={(e) => setFormData(prev => ({ ...prev, content: e.target.value }))}
              className="w-full p-2 md:p-3 rounded-xl bg-[#F0FFFD] border-none focus:ring-2 focus:ring-[#C5FFF9] text-sm md:text-base"
              rows={4}
              placeholder="Votre commentaire..."
            />
          </div>

          <div>
            <label className="block text-sm md:text-base font-medium mb-2">Numéro WhatsApp (optionnel)</label>
            <input
              type="text"
              value={formData.whatsappNumber}
              onChange={(e) => setFormData(prev => ({ ...prev, whatsappNumber: e.target.value }))}
              className="w-full p-2 md:p-3 rounded-full bg-[#F0FFFD] border-none focus:ring-2 focus:ring-[#C5FFF9] text-sm md:text-base"
              placeholder="Votre numéro WhatsApp"
            />
          </div>
        </div>

        <div className="flex justify-end space-x-3 md:space-x-4">
          <button
            onClick={onClose}
            className="px-4 md:px-6 py-2 rounded-full text-gray-600 hover:bg-gray-100 text-sm md:text-base"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            disabled={createFeedback.isLoading}
            className={`bg-primary-500 text-white py-2 px-4 md:px-6 rounded-full font-medium shadow-md hover:bg-blue-600 focus:outline-none text-sm md:text-base ${
              createFeedback.isLoading ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            {createFeedback.isLoading ? (
              <div className="flex items-center">
                <LoadingIcon /> Envoi...
              </div>
            ) : (
              'Envoyer'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}; 