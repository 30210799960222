import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import makeToast, { defaultOptions } from "../../../utils/toast";

const baseUrl = "https://bot.harvely.com/api/assistants";
const fileBaseUrl = "https://bot.harvely.com/api/vector-files";

export const useAssistantMutationForm = (callback?: any) => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    if(data.id) {
      return axios.patch(
        baseUrl+ '/'+data.id,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    return axios.post(
      baseUrl,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      queryClient.invalidateQueries(["assistants"]);
      callback?.();
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Une erreur est survenue",
        defaultOptions
      );
    },
  });
};

export const useVectorMutationForm = (callback?: any) => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
    if(data.id) {
      return axios.patch(
        fileBaseUrl+ '/'+data.id,
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
    return axios.post(
      fileBaseUrl,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      queryClient.invalidateQueries(["vector-files"]);
      callback?.();
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Une erreur est survenue",
        defaultOptions
      );
    },
  });
};

export const useAssistantPromptMutationForm = (callback?: any) => {
  const queryClient = useQueryClient();
  const paymentForm = (data: any) => {
    const token = localStorage.getItem("token");
      return axios.patch(
        baseUrl+ '/'+data.id+'/prompts',
        { ...data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  };

  return useMutation(paymentForm, {
    onSuccess: (result: AxiosResponse) => {
      queryClient.invalidateQueries(["assistants", result.data?.id]);
      callback?.();
    },

    onError: (error: AxiosError) => {
      makeToast(
        "Une erreur est survenue",
        defaultOptions
      );
    },
  });
};
