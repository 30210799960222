export enum FicheStep {
  Objectives = 'objectives',
  RevisionTeacher = 'revisionTeacher',
  RevisionStudent = 'revisionStudent',
  Situation = 'situation',
  ActivitePrincipaleTeacher = 'activitePrincipaleTeacher',
  ActivitePrincipaleStudent = 'activitePrincipaleStudent',
  SyntheseTeacher = 'syntheseTeacher',
  SyntheseStudent = 'syntheseStudent',
  Exercice = 'exercice',
  SituationSimilaires = 'situationSimilaire',
  RevisionTeacherRappel = 'revisionTeacherRappel',
  RevisionStudentRappel = 'revisionStudentRappel',
  RevisionTeacherMotivation = 'revisionTeacherMotivation',
  RevisionStudentMotivation = 'revisionStudentMotivation',
  ResumeTeacher = 'resumeTeacher',
  ResumeStudent = 'resumeStudent',
  ActiviteControleApplicationTeacher = 'activiteControleApplicationTeacher',
  ActiviteControleApplicationStudent = 'activiteControleApplicationStudent',
  ActiviteControleResearchTeacher = 'activiteControleResearchTeacher',
  ActiviteControleResearchStudent = 'activiteControleResearchStudent',
  ActiviteControleEvaluationTeacher = 'activiteControleEvaluationTeacher',
  ActiviteControleEvaluationStudent = 'activiteControleEvaluationStudent',
}

export const ficheStepsSecondaire = [
  { title: 'Objectifs', slug: FicheStep.Objectives, type: 'general', assistant: `A la fin de cette lecon, l'eleve sera capable de \n` },
  { title: 'Revision / Activité de l\'enseignant', slug: FicheStep.RevisionTeacher, type: 'teacher' },
  { title: 'Revision Eleve', slug: FicheStep.RevisionStudent, type: 'eleve' },
  { title: 'Situation', slug: FicheStep.Situation, type: 'general' },
  {
    title: 'Activite Principale enseignant',
    slug: FicheStep.ActivitePrincipaleTeacher,
    type: 'teacher',
  },
  {
    title: 'Activite Principale eleve',
    slug: FicheStep.ActivitePrincipaleStudent,
    type: 'student',
  },
  { title: 'Synthese Enseignant', slug: FicheStep.SyntheseTeacher, type: 'teacher' },
  { title: 'Synthese Eleve', slug: FicheStep.SyntheseStudent, type: 'student' },
  { title: 'Exercice', slug: FicheStep.Exercice, type: 'teacher' },
  {
    title: 'Situation Similaires',
    slug: FicheStep.SituationSimilaires,
    type: 'teacher',
  },
];

export const ficheStepsPrimaire = [
  { title: 'Objectifs', slug: FicheStep.Objectives, type: 'general', assistant: `A la fin de cette lecon, l'eleve sera capable de \n` },
  { title: 'Rappel Maitre(sse)', slug: FicheStep.RevisionTeacherRappel, type: 'teacher', assistant: ''  },
  {
    title: 'Rappel Eleve(s)',
    slug: FicheStep.RevisionStudentRappel,
    type: 'eleve',
    assistant: ''
  },
  { title: 'Motivation Maitre(sse)', slug: FicheStep.RevisionTeacherMotivation, type: 'teacher' },
  {
    title: 'Motivation Eleve(s)',
    slug: FicheStep.RevisionStudentMotivation,
    type: 'eleve',
  },
  { title: 'Situation', slug: FicheStep.Situation, type: 'general' },
  {
    title: 'Activite Principale Maitre(sse)',
    slug: FicheStep.ActivitePrincipaleTeacher,
    type: 'teacher',
  },
  {
    title: 'Activite Principale Eleve(s)',
    slug: FicheStep.ActivitePrincipaleStudent,
    type: 'student',
  },
  { title: 'Synthese Maitre(sse)', slug: FicheStep.SyntheseTeacher, type: 'teacher' },
  { title: 'Synthese Eleve', slug: FicheStep.SyntheseStudent, type: 'student' },
  { title: 'Activité de Controle / Application (Maitre (sse))', slug: FicheStep.ActiviteControleApplicationTeacher, type: 'teacher' },
  { title: 'Activité de Contole / Application Eleve ', slug: FicheStep.ActiviteControleApplicationStudent, type: 'student' },
  { title: 'Activité de Controle / Recheche (Maitre (sse))', slug: FicheStep.ActiviteControleResearchTeacher, type: 'teacher' },
  { title: 'Activité de Controle / Recherche))', slug: FicheStep.ActiviteControleResearchStudent, type: 'student' },
  { title: 'Evaluation (maitre(sse))', slug: FicheStep.ActiviteControleEvaluationTeacher, type: 'teacher' },
  { title: 'Evaluation ( Eleve )', slug: FicheStep.ActiviteControleEvaluationStudent, type: 'student' },
];

export const ficheKeys = [
  "id",
  "subject",
  "classe",
  "lessonTitle",
  "previousLesson",
  "areaOfLife",
  "currentStep",
  "revisionTeacher",
  "revisionStudent",
  "objectives",
  "situation",
  "activitePrincipaleTeacher",
  "activitePrincipaleStudent",
  "syntheseTeacher",
  "syntheseStudent",
  "exercice",
  "situationSimilaire",
  "createdAt",
  "updatedAt",
  "numberOfToken",
  "model",
  "status",
  "userId",
  "revisionTeacherRappel",
  "revisionStudentRappel",
  "revisionTeacherMotivation",
  "revisionStudentMotivation",
  "resumeTeacher",
  "resumeStudent",
  "activiteControleApplicationTeacher",
  "activiteControleApplicationStudent",
  "activiteControleResearchTeacher",
  "activiteControleResearchStudent",
  "activiteControleEvaluationTeacher",
  "activiteControleEvaluationStudent",
  "images",
  "aiImageApiLink",
  "aiImages",
];


export const ficheStepsMaternelle = [
  { title: 'Objectifs', slug: FicheStep.Objectives, type: 'general', assistant: `A la fin de cette lecon, l'eleve sera capable de \n` },
  {
    title: 'Rappelle',
    slug: FicheStep.RevisionTeacherRappel,
    type: 'teacher',
  },
  {
    title: 'Rappelle',
    slug: FicheStep.RevisionStudentRappel,
    type: 'student',
  },
  {
    title: 'Motivation',
    slug: FicheStep.RevisionTeacherMotivation,
    type: 'teacher',
  },
  {
    title: 'Motivation',
    slug: FicheStep.RevisionStudentMotivation,
    type: 'student',
  },
  {
    title: 'Situation',
    slug: FicheStep.Situation,
    type: 'general',
  },
  {
    title: 'Activite Principale',
    slug: FicheStep.ActivitePrincipaleTeacher,
    type: 'teacher',
  },
  {
    title: 'Activite Principale',
    slug: FicheStep.ActivitePrincipaleStudent,
    type: 'student',
  },
  { title: 'Synthese', slug: FicheStep.SyntheseTeacher, type: 'teacher' },
  { title: 'Synthese', slug: FicheStep.SyntheseStudent, type: 'student' },
  { title: 'Exercice', slug: FicheStep.ActiviteControleApplicationTeacher, type: 'teacher' },
  { title: 'Exercise', slug: FicheStep.ActiviteControleApplicationStudent, type: 'student' },
  { title: 'Evaluation', slug: FicheStep.ActiviteControleEvaluationTeacher, type: 'teacher' },
  { title: 'Evaluation', slug: FicheStep.ActiviteControleEvaluationStudent, type: 'student' },
];


export const getSteps = (fiche: any) => {
  if(!fiche) return ficheStepsSecondaire;
  const { classe } = fiche;
  if (Number.parseInt(classe, 10) < 7) {
    return ficheStepsPrimaire;
  }
  if (Number.parseInt(classe, 10) < 13) {
    return ficheStepsSecondaire;
  }
  return ficheStepsMaternelle;
}