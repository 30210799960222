import React, { useState } from 'react';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import InputDropdown  from '../components/inputDropdown'
import logo from '../assets/dugassistant.png'
import { LoadingIcon } from './loader';

interface SchoolOption {
  value: string;
  label: string;
}

interface TeacherIdentityDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const baseUrl = "https://bot.harvely.com/api";

const getOptionAndSection = () => {
  return {
    sections: ['A', 'B', 'C'], // Example sections
  };
};

export const TeacherIdentityDialog: React.FC<TeacherIdentityDialogProps> = ({ isOpen, onClose, onSubmit }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    classe: '',
    level: '',
    section: '',
    schoolYear: '',
    schoolId: '',
  });
  const queryClient = useQueryClient();
  const updateUser = useMutation({
    mutationFn: async (data: any) => {
      return axios.patch(`${baseUrl}/v1/auth/me`, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
    }
  });

  const { data: schools, isLoading, error } = useQuery<SchoolOption[]>({
    queryKey: ['schools'],
    queryFn: async () => {
      const response = await axios.get(baseUrl+'/schools', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          page: 1,
          limit: 1000
        }
      });

      return response?.data?.data?.map?.((school: any) => ({
        value: school.id,
        label: school.name,
      }));
    },
  });

  const createSchool = useMutation({
    mutationFn: async (name: string) => {
      const response = await axios.post(`${baseUrl}/schools`, { name }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data;
    },
    onSuccess: (newSchool) => {
      queryClient.invalidateQueries({ queryKey: ['schools'] });
      const newOption = { value: newSchool.id, label: newSchool.name };
      setFormData((prev) => ({ ...prev, schoolId: newOption.value }));
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption: SchoolOption | null) => {
    setFormData((prev) => ({ ...prev, schoolId: selectedOption ? selectedOption.value : '' }));
  };

  const handleCreateSchool = async (inputValue: string) => {
    try {
      await createSchool.mutateAsync(inputValue);
    } catch (error) {
      console.error('Error creating school:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      await updateUser.mutateAsync({ school: formData.schoolId });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const { sections } = getOptionAndSection();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Formulaire d'identité de l'enseignant"
      className="md:w-[600px] w-[90vw] md:px-0 px-4 mx-auto rounded-3xl shadow-lg flex flex-col justify-between z-[200]"
      style={{
        content: {
          borderRadius: '24px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          backdropFilter: 'blur(8px)',
          zIndex: 1000,
        },
      }}
    >
      <div className="flex flex-col items-center">
        <div className="bg-white mt-4 rounded-full w-24 h-24 flex items-center justify-center shadow-md mb-8">
          <div className="text-blue-500 text-4xl font-bold rounded-xl w-16 h-16 flex items-center justify-center">
          <img src={logo} width={60} className="rounded-[50px]" />
          </div>
        </div>
        
        {step === 1 && (
          <div className="text-center md:px-6 px-2">
            <h2 className="md:text-3xl text-xl font-bold mb-4 text-gray-900">Bienvenue à la version 2!</h2>
            <p className="mb-8 text-gray-700 md:text-lg text-sm">
              Nous passons à la version 2 de notre application. Pour vous remercier de votre fidélité, nous vous offrons <span className="font-bold">10 fiches gratuites</span>.
            </p>
            <button 
              onClick={() => setStep(2)} 
              className=" bg-primary-500 text-white py-3 px-8 rounded-full text-lg font-medium shadow-md hover:bg-blue-600 focus:outline-none"
            >
              Continuer
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="text-center md:px-6 px-2">
            <h2 className="md:text-3xl text-xl font-bold mb-4 text-gray-900">Sélectionnez ou ajoutez votre école</h2>
            <div className="mb-8">
              {isLoading  ? (
                <p>Chargement des écoles...</p>
              ) : error ? (
                <p>Erreur lors du chargement des écoles</p>
              ) : (
                <div className="relative">
                  <CreatableSelect
                    options={schools}
                    onChange={handleSelectChange}
                    onCreateOption={handleCreateSchool}
                    isClearable
                    isDisabled={createSchool.isLoading}
                    placeholder="Sélectionnez ou créez une école"
                    className="text-left"
                    value={schools?.find(option => option.value === formData.schoolId)}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '9999px',
                        backgroundColor: '#F0FFFD',
                        borderColor: state.isFocused ? '#C5FFF9' : '#F0FFFD',
                        boxShadow: state.isFocused ? '0 0 0 1px #F0FFFD' : 'none',
                        '&:hover': {
                          borderColor: '#F0FFFD'
                        },
                        padding: '2px 8px'
                      }),
                      menu: (baseStyles) => ({
                        ...baseStyles,
                        borderRadius: '16px',
                        overflow: 'hidden'
                      })
                    }}
                    formatCreateLabel={(inputValue) => `Ajouter "${inputValue}"`}
                  />
                  {createSchool.isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white/50 rounded-full">
                      <LoadingIcon />
                    </div>
                  )}
                </div>
              )}
            </div>
            <button 
              onClick={() => handleSubmit()} 
              className="bg-primary-500 flex items-center mx-auto justify-center text-white py-3 px-8 rounded-full text-base font-medium shadow-md hover:bg-blue-600 focus:outline-none"
            >
              Enregistrer
              {updateUser.isLoading && <span className="ml-2">
                <LoadingIcon />
                </span>}
            </button>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-10 mb-4">
        {[1, 2].map((dot) => (
          <div 
            key={dot}
            className={`h-2 w-2 rounded-full mx-1 ${step === dot ? 'bg-black' : 'bg-gray-300'}`}
          ></div>
        ))}
      </div>
    </Modal>
  );
}; 