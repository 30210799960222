import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/home";
import SituationListPage from "../pages/situations/list";
import PaymentList from "../pages/payment/list";
import SituationDetailPage from "../pages/situations/detail";
import { CreateFiche } from "../pages/situations/full/create-fiche";
import { ListFiche } from "../pages/situations/full/list";
import Ressources from "../pages/ressources";
import Profile from "../pages/profile";
import Prevision from "../pages/classes";
import Classes from "../pages/classes/classe";
import TeachersList from "../pages/admin/list";
import Dashboard from "../pages/admin/dashbord";
import AdminSituations from "../pages/admin/assistants";
import AdminPrompt from "../pages/admin/prompt";
import AdminFichier from "../pages/admin/fichiers";
import { CreateFicheTimeline } from "../pages/situations/full/create-fiche-timeline";
import React, { useState, useEffect } from 'react';
import { TeacherIdentityDialog } from '../components/TeacherIdentityDialog';

const AppRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/situations" element={<SituationListPage />} />
        <Route path="/situations/detail" element={<SituationDetailPage />} />
        <Route path="/fiches/:id" element={<CreateFicheTimeline />} />
        <Route path="/previsions" element={<Prevision />} />
        <Route path="/teachers" element={<TeachersList />} />
        <Route path="/classes" element={<Classes />} />
        <Route path="/ressources" element={<Ressources />} />
        <Route path="/fiches" element={<ListFiche />} />
        <Route path="/payments" element={<PaymentList />} />
        <Route path="/profile" element={<Profile />} />
        <Route element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export const AdminRoutes = () => {    

  return (
    <div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/situations" element={<SituationListPage />} />
      <Route path="/situations/detail" element={<SituationDetailPage />} />
      <Route path="/fiches/:id" element={<CreateFicheTimeline />} />
      <Route path="/previsions" element={<Prevision />} />
      <Route path="/teachers" element={<TeachersList />} />
      <Route path="/classes" element={<Classes />} />
      <Route path="/ressources" element={<Ressources />} />
      <Route path="/fiches" element={<ListFiche />} />
      <Route path="/payments" element={<PaymentList />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/assistants" element={<AdminSituations/>} />
      <Route path="/assistants/:id/prompts" element={<AdminPrompt/>} />
      <Route path="/assistant-fichiers" element={<AdminFichier/>} />
      <Route element={<Navigate to="/" />} />
    </Routes>
    </div>
  );
};

export default AppRoutes;
