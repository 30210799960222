import Input from "../../../components/input";
import { useGptRessourceForm } from "./hook";
import { Formik } from "formik";
import Select from "../../../components/inputDropdown";
import { courses } from "../../../utils/lessons";
import { LoadingIcon } from "../../../components/loader";


interface File {
    name: string;
    size: number;
    type: string
}

const RessourceForm: React.FC<{course?: string, onSuccess?: (id: string) => void, onClose?: () => void}> = (props) => {
  const mutation = useGptRessourceForm((data: any) => {
    if(props.onSuccess) {
      props.onSuccess(data);
    }
  });

  return (
    <div>
      <Formik
        initialValues={{}}
          onSubmit={(values: any) => {
            const payment = {
              ...values,
              course:props.course || values.course,
            };
            mutation.mutate(payment);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <div className="flex flex-col">
              <Input placeholder={"Titre"} name={""} onChange={(e:any) => {
                
                setFieldValue("title", e);
              }} />
              <textarea placeholder="Contenu" className="w-full h-[300px] border-[1px] border-[#000000] mt-4 rounded-[15px] p-3" onChange={(e) => {
                setFieldValue("content", e.target.value);
              }}/>

              {!props.course && <Select
                label="Type de ressource"
                placeholder="Selectionner"
                items={courses.map((course) => ({ value: course.label, label: course.label }))}
                onSelect={function (item: string): void {
                  setFieldValue("course", item);
                }}
              />}
              {/* <Files
                className='files-dropzone'
                onChange={handleChange}
                onError={handleError}
                accepts={['image/png', '.pdf', 'audio/*']}
                multiple
                maxFileSize={10000000}
                minFileSize={0}
                clickable
              >
                <div className="flex flex-col items-center justify-center gap-2 p-2 h-20 border-2 border-[#000000]/60 border-dashed rounded-[15px] mt-8">
                  <FaFileUpload className="w-10 h-10 text-[#0E9F90]/70" />
                  <p className="text-[#0E9F90]/70 text-[12px]">Clique ici pour ajouter un fichier</p>
                </div>
              </Files>
              {uploadedFiles.length > 0 && (
                <div className="mt-8">
                  <ul>
                    {uploadedFiles.map((file, index) => (
                      <li key={index} className="text-[#0E9F90] mt-2 text-[14px]">{file.name}</li>
                    ))}
                  </ul>
                </div>
              )} */}
      
              <div className="flex flex-row gap-3">
                <button
                  onClick={()=> props.onClose?.()}
                  className="bg-[#000] text-[#fff] rounded-[5px] py-2 px-10 w-[150px] mt-8"
                >
                  Annuler
                </button>
                <button
                  onClick={()=>handleSubmit()}
                  className="bg-[#0E9F90] text-[#fff] rounded-[5px] py-2 px-10 w-[150px] mt-8"
                >
                  Enregistrer {mutation.isLoading && <LoadingIcon height={'h-[20px] w-[20px]'} />}
                </button>
                
              </div>
            </div>
          )}
      </Formik>
    </div>
  );
};

export const RessourceFormModal: React.FC<{course?: string, onSuccess?: (id: string) => void, onClose?: () => void}> = (props) => {
  return (
    <div className="bg-[#000]/20 fixed w-full h-full top-0 left-0 p-5 md:p-10 z-[90000] overflow-y-scroll">
      <div className="bg-[#fff] w-full md:w-3/4 p-5 mx-auto my-auto">
        <p className="text-[16px] font-bold my-4">Ajouter un texte / support</p>
        <RessourceForm course={props.course} onSuccess={props.onSuccess} onClose={props.onClose}/>
      </div>
    </div>
  );
}

export default RessourceForm;