import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import validators from "./validators";
import { useSignup } from "./hooks";
import Input from "../../../components/input";
import Button from "../../../components/button";

interface IFormInput {
  email: string;
  phoneNumber: string;
  password: string;
  firstName: string;
  lastName: string;
}

const Signup: React.FC = () => {
  const { register, handleSubmit, formState: {errors} } = useForm<IFormInput>();
  const navigate = useNavigate();
  const [error, setError] = React.useState<string>("");
  const mutation = useSignup(navigate, setError);
  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    mutation.mutate({
      password: data.password,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      passwordConfirmation: data.password,
      phoneNumber: data.phoneNumber || undefined,
    });
  };

  return (
    <div className="flex sm:flex-col md:flex-row lg:flex-row h-screen bg-yellow items-center justify-between">
      <div className="flex flex-col p-4 md:p-8 lg:p-8 gap-20 lg:gap-12 items-center justify-center h-screen w-[100%] md:w-[100%] lg:w-[100%] md:bg-primary50/10 lg:bg-primary50/10">
        <div className="overflow-scroll border-[2px] border-border shadow-lg rounded-[15px] p-8 bg-secondary50 md:w-[60%] lg:w-[40%] sm:w-[90%]">
          <p className="font-bold text-[16px] py-3">Veuillez remplir ce formulaire pour commencer A generer des situations.</p>
          <form onSubmit={handleSubmit(onSubmit)} className="w-[100%]">
            {Object.values(errors).length ? <div className="bg-[#FEC3C3] rounded-[10px] px-5 py-2 my-2">
              Veuillez bien remplir le formulaire
            </div> : null}
            { error === 'email' ? <div className="bg-[#FEC3C3] rounded-[10px] px-5 py-2 my-2">
              L'addresse email que vous avez entré est déjà utilisé, veuillez en choisir une autre
            </div> : error === 'phoneNumber' ? <div className="bg-[#FEC3C3] rounded-[10px] px-5 py-2 my-2">
              Le numéro de téléphone que vous avez entré est déjà utilisé, veuillez en choisir un autre
            </div> : error === 'phoneNumber' ? <div className="bg-[#FEC3C3] rounded-[10px] px-5 py-2 my-2">
              Le numéro de téléphone que vous avez entré est déjà utilisé, veuillez en choisir un autre
            </div> : !error ? null : <div className="bg-[#FEC3C3] rounded-[10px] px-5 py-2 my-2">
              Nous n'avons pas pu enregistrer votre compte, veuillez Reesayer
              </div>
            }

            <div className="w-full flex md:flex-row sm:flex-col md:justify-between items-center gap-4">
              <div className="md:w-[47%] sm:w-[100%]">
                <Input
                  type="name"
                  name="firstName"
                  register={register}
                  validator={validators["firstName"]}
                  label="Nom"
                  placeholder="Ex: Mujumpe"
                  inputStyle={errors.firstName ? "border border-[red] ": ""}
                />
              </div>
              <div className="md:w-[47%] sm:w-[100%]">
                <Input
                  type="name"
                  name="lastName"
                  register={register}
                  validator={validators["lastName"]}
                  label="Post-nom"
                  placeholder="Ex: Unga-bunga"
                  inputStyle={errors.lastName ? "border border-[red] ": ""}
                />
              </div>
            </div>
            <div className="flex md:flex-row sm:flex-col md:justify-between items-center gap-4">
              <div className="md:w-[47%] sm:w-[100%]">
                  <Input
                    type="email"
                    name="email"
                    register={register}
                    validator={validators["email"]}
                    label="Email (optionnel)"
                    placeholder="Ex: example@gmail.com"
                    inputStyle={errors.email ? "border border-[red] ": ""}
                  />
                </div>
              <div className="md:w-[47%] sm:w-[100%]">
                <Input
                  type="phoneNumber"
                  name="phoneNumber"
                  register={register}
                  validator={validators["phoneNumber"]}
                  label="Numero de telephone"
                  placeholder="Ex: 0991734567"
                  inputStyle={errors.phoneNumber ? "border border-[red] ": ""}
                />
              </div>
            </div>
            <Input
              type="password"
              name="password"
              register={register}
              validator={validators["password"]}
              label="Mot de passe"
              placeholder="Ex: example$20"
              inputStyle={errors.password ? "border border-[#FF0000] border-[1px] ": ""}
            />
            <Button
              isLoading={mutation.isLoading}
              label={mutation.isLoading ? "" : "Créer un compte"}
              height="h-[40px]"
              textColor="white"
              isSubmit
            />
          </form>
          <div className="flex flex-row items-center justify-center mt-4">
            <Link to={"/login"} className="">
              Vous avez déjà un compte ? <b>Connectez-vous.</b>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
