import React from "react";
import { Formik } from "formik";
import { useState } from "react";

import { usePaymentForm, usePaymentFormCard } from "../mutation";
import { BsArrowLeft } from "react-icons/bs";
import { useUserProfile } from "../../profile/hooks";
import { LoadingIcon } from "../../../components/loader";
import Button from "../../../components/button";
import InputDropdown from "../../../components/inputDropdown";
import Input from "../../../components/input";

const PriceForm: React.FC<{paymentData: any, onClose: any, paymentText?: string, paymentDataText?: string}> = ({paymentData, onClose, paymentText = 'Paiement', paymentDataText}) => {
  const [selectedOption, setSelectedOption] = useState<any>();
  const [paymentMethod, setPaymentMethod] = useState<any>("MOBILEMONEY");
  const [resultData, setResultData] = useState<any>(null);
  const mutation = usePaymentForm((data: any) => {
    setResultData(data);
    setSelectedOption(undefined);
    localStorage.removeItem("paymentData");
    window.location.assign('/')
  });

  const mutationCard = usePaymentFormCard((data: any) => {
    setSelectedOption(undefined);
    localStorage.removeItem("paymentData");
    onClose();
    window.open(data.data, "_blank");
  });
  const [showNotice, setShowNotice] = useState<boolean>(false);
  const {data: user} = useUserProfile();

  return (
    <div className="fixed inset-0 flex items-center justify-center  bg-primary50 bg-opacity-60 z-50">
          <div className="relative bg-secondary50 p-8 rounded-lg shadow-lg w-full md:w-[40%] mx-auto">
            <button
              onClick={onClose}
              className="absolute top-4 right-8 text-black hover:text-gray-700 font-bold text-4xl"
              aria-label="Close"
            >
              &times;
            </button>
    <div>
      <div className="p-8">
      {mutation.isLoading && (
        <div className=" bg-white p-5 text-black bg-opacity-95 fixed w-full h-full top-0 left-0 z-50 text-lg text-left">
          <p className="font-bold">
            Veuillez vérifier votre téléphone pour insérer
            <br />
            le code de confirmation.
          </p>
          <p className="mt-10">
            Nous sommes en train de traiter votre demande. Veuillez faire preuve
            de patience
            <br />
            et ne fermez pas cette fenêtre !
          </p>
          <div className="ml-[10px] mt-10">
            <LoadingIcon height={'h-[24px] w-[24px]'} />{" "}
          </div>
        </div>
      )}
      {!mutation.isLoading && <p className="text-lg text-black font-bold mb-5">
        {paymentText} <span className="text-primary50 text-base bg-primary-500/50 ml-3 py-1 px-3 rounded-md">{paymentDataText}</span>
      </p>}
      <Formik
        initialValues={{
          amount: selectedOption?.price?.toString(),
          currency: "USD",
        }}
        onSubmit={(values) => {
          const payment = {
            ...values,
            ...paymentData,
          };
          if(paymentMethod === 'CARD') mutationCard.mutate(payment);
          else mutation.mutate(payment);
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <div className="flex flex-col gap-5 verflow-y-scroll no-scrollbar">
            {!(!mutation.isLoading && resultData?.status === "success") && (
              <>
                <div>
                    <input type="radio" checked={paymentMethod === 'MOBILEMONEY'} name="paymentMethod" value="MOBILEMONEY" onChange={() => setPaymentMethod("MOBILEMONEY")} />
                    <label className=" text-primary-400 mx-3" htmlFor="MOBILEMONEY">Mobile Money</label>
                    <input type="radio" checked={paymentMethod === 'CARD'} name="paymentMethod" value="CARD" onChange={() => setPaymentMethod("CARD")} />
                    <label className=" text-primary-400 mx-3" htmlFor="CARD">Carte Bancaire</label>
                </div>
                {paymentMethod === "MOBILEMONEY" && (
                  <>
                    <InputDropdown
                    label="Devise (USD, CDF)"
                    placeholder="Selectionner"
                    items={[
                        { value: "USD", label: "USD" },
                        { value: "CDF", label: "CDF" },
                    ]}
                    onSelect={function (item: string): void {
                        setFieldValue("currency", item);
                    }}
                    />
                    <InputDropdown
                      label="Operateur telephonique"
                      placeholder="Selectionner"
                      items={[
                        { value: "MPESA", label: "VODACOM" },
                        { value: "ORANGE", label: "ORANGE" },
                        { value: "AIRTEL", label: "AIRTEL" },
                      ]}
                      onSelect={function (item: string): void {
                        setFieldValue("provider", item);
                      }}
                      selectedItem={user?.role?.name.toLowerCase() === 'admin' ? 'MPESA' : undefined}
                    />
                    <Input
                      onChange={handleChange("phoneNumber")}
                      icon=""
                      label={"Numero de telephone (+243xxx)"}
                      placeholder={""}
                      inputStyle="rounded-[5px]"
                      style="mt-5"
                      name={"phoneNumber"}
                      defaultValue={user?.role?.name.toLowerCase() === 'admin' ? '243990445626' : undefined}
                    />
                  </>
                )}

                {paymentMethod === 'CARD' && (
                    <InputDropdown
                        label="Devise (USD, CDF)"
                        placeholder="Selectionner"
                        items={[
                            { value: "USD", label: "USD" },
                            { value: "CDF", label: "CDF" },
                        ]}
                        onSelect={function (item: string): void {
                            setFieldValue("currency", item);
                        }}
                    />
                )}

                <div className="w-[150px]">
                <Button
                    label={"Payer"}
                    height="h-[44px] rounded-[5px] self-start w-[150px] m-0 "
                    textColor="white"
                    onClick={() => {
                        //setShowNotice(true);
                        if(paymentMethod === 'CARD') {
                        handleSubmit();
                        } else {
                            setShowNotice(true);
                        }
                    }}
                    isLoading={mutation.isLoading}
                />
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
      </div>
    </div>
    </div>
    </div>
  );
};

export default PriceForm;
