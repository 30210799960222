import { useState } from "react";
import { Layout } from "../../components/layout";
import { FiFileText } from "react-icons/fi";
import AssistantForm from "./forms/situations";
import { getAllCourses, getCoursesFromOptionValue } from "../../utils/sections";
import { Assistant, useAssistantsList } from "../payment/fetch";
import { Edit } from "react-feather";
import { useAssistantMutationForm } from "./forms/mutation";
import { LoadingIcon } from "../../components/loader";

const AdminSituations: React.FC = () => {
  const [showCreateAssistant, setShowCreateAssistant] =
  useState<boolean>(false);

  const mutation = useAssistantMutationForm();

  const [editingAssistant, setEditingAssistant] = useState<Assistant>();
  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowCreateAssistant(false);
  };

  if (showCreateAssistant) {
    sidePannelView = <AssistantForm onSuccess={() => {
      setShowCreateAssistant(false);
      setEditingAssistant(undefined);
    }} assistant={editingAssistant} />;
    sidePannelStyle = "w-[35%]";
    sidePannelTitle = "Assistants";
  }

  const {data} = useAssistantsList();
  
  return (
    <Layout 
    activeMenu="Acceuil"
    sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
       <div className="p-8">
       <h1 className="font-bold text-[18px] ">Assistants</h1>

       <div className="mt-[32px]">
       <button 
          className="bg-[#0E9F90] text-[#FFF] px-6 py-3 rounded-md flex flex-row items-center justify-center gap-2"
          onClick={() => {
            mutation.mutate({})
          }}
          >
            <FiFileText className="text-[24px]"/>
            Refresh
            {mutation?.isLoading && (
              <LoadingIcon height={20} />
            )}
        </button>
       </div>

       <div className=" w-[100%] mt-10">
      <table className="max-h-[60%] overflow-auto mt-6 w-full">
        <thead className="mx-5 text-blue w-full bg-yellow">
          <tr className="w-full">
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Name
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Courses
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Niveaux
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
            {data?.map?.(item => (
            <tr
              className=" border-t-[16px] border-[#fff] bg-[#0E9F90]/20"
            >
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                  {item.name}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                 {item.courses?.join(',')}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                 {item.levels}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                  <div className="flex flex-row items-center gap-6">                   
                    <a 
                    href={`/assistants/${item.id}/prompts`}
                  className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                    Prompt
                  </a>
                  <button 
                  className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                    <FiFileText className="text-[20px] text-[#0E9F90]"/>
                    Files
                  </button>
                  <button 
                  className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2" 
                  onClick={() => {
                    setEditingAssistant(item);
                    setShowCreateAssistant(true);
                  }}
                  >
                    <Edit className="text-[20px] text-[#0E9F90]"/>
                    Modifier
                  </button>
                  </div>
              </td>
            </tr>
            ))}
        </tbody>
      </table>
      </div>
       </div>
    </Layout>
  );
};

export default AdminSituations;
