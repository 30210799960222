import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Layout } from "../../components/layout";
import { BsPlusSquare } from "react-icons/bs";
import { LoadingIcon } from "../../components/loader";
import { useGptForm } from "../situations/mutation";
import Select from "../../components/inputDropdown";
import { BiSolidRightArrow } from "react-icons/bi";
import Pagination from "../../components/pagination";
import RessourceForm from "./form";
import { useRessourceListQuery } from "./form/hook";

const Ressources: React.FC = () => {
  const navigate = useNavigate();

    const [showRessourceForm, setShowRessourceForm] =
    useState<boolean>(false);
  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowRessourceForm(false);
  };


  if (showRessourceForm) {
    sidePannelView = <RessourceForm />;
    sidePannelStyle = "";
    sidePannelTitle = "Ajouter une ressource";
  }

  const mutation = useGptForm((_s: string, id: string) => {
    navigate(`/situations/detail?ref=${id}`);
  });


  const [page, setPage] = useState(1);
  const {data} = useRessourceListQuery(5, page, '');

  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };
const totalPages = data ? Math.ceil(data[1] / 5) : 0;
  return (
    <Layout
      activeMenu="Acceuil"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      {mutation.isLoading && (
        <div className="absolute h-full w-full top-0 left-0 bg-primary50/50 flex flex-row justify-center items-center">
          <div className=" md:w-[35%] ">
            <div className=" p-5 rounded-[10px] bg-secondary50 bg-opacity-95  w-full h-full z-50 text-lg text-center flex justify-center items-center flex-col">
              <p className="font-semibold text-[14px]">
                Veuillez patienter, nous traitons votre demande...
              </p>
              <div className="ml-[10px] mt-5">
                <LoadingIcon height={'h-[24px] w-[24px]'} />{" "}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className=" px-5 pt-2 sm:pt-4">

      </div>
      <div className="flex flex-row  items-center justify-between md:mt-15 sm:mt-8 px-5">
      <div className="flex flex-row  items-center gap-6 md:mt-15 sm:mt-8 px-5">
      <h2 className="text-primary50/80 text-[20px] sm:w-full font-bold uppercase ">
          MES RESSOURCES
        </h2>
        <BsPlusSquare
          onClick={() => {
            setShowRessourceForm(true);
          }}
          className="cursor-pointer"
        />
      </div>
        <Select 
          items={[]} 
          onSelect={(item: string) => console.log('')}
          containerStyle="w-[300px]"
          />
      </div>
      <div className="px-5 mt-10">
      <div className="flex grid grid-cols-2 gap-16 mt-10  p-0 md:p-0 md:hidden">

        {data?.[0]?.map(situation => <div key={situation.id} className={`bg-[#0E9F90]/10 rounded-[15px] p-8`}>
        <p className="text-primary-900 font-semibold text-[20px] overflow-x-hidden">
        1Ere  Commercial Gestion
        </p>
        <p className="text-primary50 font-medium text-[20px] my-4 overflow-x-hidden">
        CS. MAMA MULEZI
        </p>
        <div className="flex flex-row items-center gap-1">
        <BiSolidRightArrow className="text-primary50"/>
        <p className="text-primary50 text-[16px] overflow-x-hidden">
        la logique mathematique
        </p>
            </div>
        </div>)}
      </div>

      <table className="table p-5 overflow-auto md:overflow-visible md:table-fixed md:w-full sm:hidden">
        <thead className="mx-5 bg-[#0E9F90] text-[#fff] w-full rounded-tl-[15px] rounded-tr-[15px] ">
          <tr className="w-full">
            <th className=" text-[14px] font-semibold text-left pl-5 py-4 bg-primary-500">
             Titre
            </th>
            <th className=" text-[14px] font-semibold text-left pl-5 py-4 bg-primary-500">
              Niveau
            </th>
            <th className="text-[14px] font-semibold text-left pl-5 py-4 bg-primary-500">
              Téléphone
            </th>
          </tr>
        </thead>

        <tbody>
        {data?.[0]?.map((situation, index) =>
              <tr
                key={situation.id}
                className={`bg-[#0E9F90] ${index % 2 === 0 ? 'bg-opacity-10' : 'bg-opacity-5'}`}
              >
                <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4">
                  1Ere  Commercial Gestion
                </td>
                <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4">
                  CS. MAMA MULEZI
                </td>
                <td className="text-primary-900 font-normal text-sm text-left pl-5 pt-5">
                  Logique
                </td>
              </tr>
            )}
        </tbody>
      </table>
      {data && (
                <Pagination
                    pagesCount={totalPages} // Assuming totalPages is available in your response data
                    currentPage={page - 1} // Adjusting page number to start from 0
                    onPageChange={handlePageChange}
                />
            )}
      </div>
    </Layout>
  );
};

export default Ressources;
