import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export type Payment = {
  id: number;
  amount: string;
  status: string;
  metadata: any;
  createdAt: string;
};

export type Prompt = {
  prompt: string;
  variables: string[]
}

export type Assistant = {
  id: string;
  name: string;
  prompts: Record<string, Prompt>;
  courses: string[];
  levels: string;
}

export type VectorFile = {
  id: string;
  title: string;
  name: string;
  courses: string[]
}

export const usePaymentList = (tokens: string) => {
  return useQuery(["payments"], async (): Promise<Payment[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get("https://bot.harvely.com/api/payments", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};

export const useAssistantsList = () => {
  return useQuery(["assistants"], async (): Promise<Assistant[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get("https://bot.harvely.com/api/assistants", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};

export const useVectorFileList = () => {
  return useQuery(["vector-files"], async (): Promise<VectorFile[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get("https://bot.harvely.com/api/vector-files", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};

export const useAssistantSingle = (id: string) => {
  return useQuery(["assistants", id], async (): Promise<Assistant> => {
    const token = localStorage.getItem("token");
    const result = await axios.get("https://bot.harvely.com/api/assistants/"+id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || null;
  });
};


export const useUserList = (search: string) => {
  return useQuery(["users", {search}], async (): Promise<any[]> => {
    const token = localStorage.getItem("token");
    const result = await axios.get(`https://bot.harvely.com/api/users?search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data || [];
  });
};