import React, { useEffect, useState } from "react";
import AuthRoutes from "../routes/auth";
import AppRoutes, { AdminRoutes } from "../routes/app";
import { UserContext } from "../context/user.context";
import { User } from "../features/users/types/user";
import { useUserProfile, useUpdateUser } from "./profile/hooks";
import { useLocation } from "react-router-dom";
import {GoogleOAuthProvider} from '@react-oauth/google'
import { prices } from "../utils/prices";
import { payAsYouGoOptions } from "./payment/price";
import { TeacherIdentityDialog } from "../components/TeacherIdentityDialog";

const App: React.FC = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");

  const selectedPayment = new URLSearchParams(location.search).get("paymentType");
  const selectedPaymentQuantity = new URLSearchParams(location.search).get("quantity");

  
  useEffect(() => {
    if (course && lesson && areaOfLife) {
      localStorage.setItem(
        "situationInProgress",
        JSON.stringify({ course, lesson, areaOfLife, previousLesson })
      );
    }
    if (selectedPayment && selectedPaymentQuantity) {
      localStorage.setItem("paymentData", JSON.stringify({
        paymentType: selectedPayment,
        tokenPurchased: selectedPayment === 'PAY_AS_YOU_GO' ? selectedPaymentQuantity : undefined,
        subscriptionLength: selectedPayment === 'SUBSCRIPTION' ? selectedPaymentQuantity : undefined,
        price: selectedPayment === 'SUBSCRIPTION' ? prices.find(e => e.selectedLength === Number.parseInt(selectedPaymentQuantity))?.price : payAsYouGoOptions.find(e => e.value === Number.parseInt(selectedPaymentQuantity))?.price,
      }));
    }
  }, []);

  const profile = useUserProfile(token || "", true);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogSubmit = (data: any) => {
    updateUser.mutate({
      school: data.school,
    })
  };

  const updateUser = useUpdateUser(token || "", true);

  useEffect(() => {
    if (profile?.data && profile?.data?.school) {
      setIsDialogOpen(false);
    } else if (profile?.data && !profile?.data?.school) {
      setIsDialogOpen(true);
    } else if (!profile?.data) {
      setIsDialogOpen(false);
    }
  }, [profile?.data?.school]);
  

  return (
    <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID || '1060554873119-2bt08s87rn1tje2dorpoj4uvp9hpg7kl.apps.googleusercontent.com'}>
      {!token ? (
        <AuthRoutes />
      ) : (
        <UserContext.Provider value={{ ...(profile.data || ({} as User)) }}>
          {!isDialogOpen ? undefined : <TeacherIdentityDialog isOpen={isDialogOpen} onClose={handleDialogClose} onSubmit={handleDialogSubmit} />}
          {profile?.data?.role?.name?.toLocaleLowerCase() === 'admin'  ? <AdminRoutes /> : <AppRoutes />}
        </UserContext.Provider>
      )}
    </GoogleOAuthProvider>
  );
};

export default App;
