import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from '../../../features/users/types/user';
import { api, getApi } from '../../../utils/api';
import makeToast, { defaultOptions } from '../../../utils/toast';
import { logout } from '../../../utils/auth';
import axios, { AxiosError } from 'axios';

export const useUserProfile = (token = '', validateToken = false) => {
  const client = useQueryClient()
  return useQuery(
    ['user-profile'],
    async (): Promise<User | null> => {
      if(!token && validateToken) throw new Error('No User');
      try {
        const data = await getApi('v1/auth/me');
        if (data && data?.data.email) {
          return data?.data;
        }
        return {} as User;
      } catch(err: any) {
        if(err?.response) {
          if(err?.response?.status === 401) {
            makeToast('Session Expirée', defaultOptions);
            logout(client);
          } else {
            makeToast('Une erreur est survenue, nos équipes travaillent sur une solution, veuillez patienter !')
          }
        } else {
          makeToast('Veuillez verifier votre connexion internet !')
        }

        return {} as User;
      }
      //throw new Error('User not found');
    },
    {
      retry: 0,
      networkMode: 'online',
      useErrorBoundary: false,
      // staleTime: 10 * (60 * 1000), // 10 mins 
      // cacheTime: 15 * (60 * 1000), // 15 mins 
      onError: (error: any) => {
        if (error?.status === 401) {
          makeToast('Session Expirée', defaultOptions);
          logout(client);
        }
      },
    },
  );
};

export const useUpdateUser = (token = '', validateToken = false) => {
  const client = useQueryClient()
  return useMutation(
    ['update-user'],
    async (data: {school: string}) => {
      const response = await axios.patch(`${process.env.REACT_APP_API_URL}/v1/auth/me`, data, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response?.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['user-profile'])
      }
    }
  );
};