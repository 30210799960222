import { useState } from "react";
import { Layout } from "../../components/layout";
import { FiFileText } from "react-icons/fi";
import FichiersForm from "./forms/fichiers";
import { useVectorMutationForm } from "./forms/mutation";
import { LoadingIcon } from "../../components/loader";
import { useVectorFileList, VectorFile } from "../payment/fetch";
import { Edit } from "react-feather";

const AdminFichier: React.FC = () => {
    const [showCreateFichier, setShowCreateFichier] =
    useState<boolean>(false);
    let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowCreateFichier(false);
  };

  const [editing, setEditing] = useState<VectorFile>();

  if (showCreateFichier) {
    sidePannelView = <FichiersForm vectorFile={editing} onSuccess={() => {
      setShowCreateFichier(false);
      setEditing(undefined);
    }}/>;
    sidePannelStyle = "w-[35%]";
    sidePannelTitle = "Fichiers";
  }

  const mutation = useVectorMutationForm();
  const {data} = useVectorFileList();
   
  return (
    <Layout 
    activeMenu="Acceuil"
    sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
       <div className="p-8">
       <h1 className="font-bold text-[18px] ">Fichiers</h1>

       <div className="mt-[32px]">
       <button 
         className="bg-[#0E9F90] text-[#FFF] px-6 py-3 rounded-md flex flex-row items-center justify-center gap-2"
         onClick={() => {mutation.mutate({})}}
         >
                    <FiFileText className="text-[24px]"/>
                    Refresh
                    {mutation.isLoading && (
                  <LoadingIcon height={16} />
                )}
                  </button>
       </div>

       <div className="hidden md:block w-[100%] mt-10">
      <table className="max-h-[60%] overflow-auto mt-6 w-full">
        <thead className="mx-5 text-blue w-full bg-yellow">
          <tr className="w-full">
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Name
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Title
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Courses
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
            {data?.map(item => (
              <tr
              className=" border-t-[16px] border-[#fff] bg-[#0E9F90]/20"
            >
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {item.name}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {item.title}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {item.courses?.join(',')}
              </td>
              
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                  <div className="flex flex-row items-center gap-6">                   
                  <button 
                    onClick={() => {
                      setShowCreateFichier(true);
                      setEditing(item);
                    }}
                    className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                      <Edit className="text-[20px] text-[#0E9F90]"/>
                      Edit
                  </button>
                  </div>
              </td>
            </tr>))}
        </tbody>
      </table>
      </div>
       </div>
    </Layout>
  );
};

export default AdminFichier;
