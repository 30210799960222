import Input from "../../../components/input";
import { Formik } from "formik";
import { useAssistantPromptMutationForm } from "./mutation";
import { useRef, useState } from "react";
import { ficheKeys, ficheStepsMaternelle, ficheStepsPrimaire, ficheStepsSecondaire } from "../../../utils/fiche";
import Select from "../../../components/inputDropdown";
import { Assistant } from "../../payment/fetch";

function extractPlaceholders(input: string): string[] {
  const regex = /\{\{(.*?)\}\}/g; // Matches text within {{ }}
  const matches: string[] = [];
  let match;

  while ((match = regex.exec(input)) !== null) {
    matches.push(match[1].trim()); // Add the text inside {{ }} to the array, trimmed
  }

  return matches;
}

const PromptForm: React.FC<{assistant: Assistant, id?: string, onSuccess?: (id: string) => void}> = (props) => {
  const mutation = useAssistantPromptMutationForm(() =>{
    props?.onSuccess?.('');
  })

  const [value, setValue] = useState<string>('');
  const [variable, setVariable] = useState<string>('');

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleInsertText = (text: string) => {
    const textarea = textareaRef.current;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      // Insert text at cursor position
      const newValue =
        value.slice(0, start) + text + value.slice(end);
      setValue(newValue);

      // Move cursor to after inserted text
      const newCursorPosition = start + text.length;
      requestAnimationFrame(() => {
        textarea.selectionStart = newCursorPosition;
        textarea.selectionEnd = newCursorPosition;
        textarea.focus();
      });
    }
  };

  const [level, setLevel] = useState('');
  const [step, setStep] = useState('');

  return (
    <div>
      <div className="flex flex-row my-3 gap-5">
        <select className="border-[1px] border-primary50 rounded-md" onChange={(e) => {
          if(e.target.value) {
            setVariable(e.target.value);
          }
        }}>
          {ficheKeys.map(f => <option value={f}>{f}</option>)}
        </select>
        <button onClick={() => {
          if(variable) handleInsertText(`{{ ${variable} }}`)
        }} >Inserer</button>
      </div>
      <Formik
        initialValues={{}}
          onSubmit={(values: any) => {
            mutation.mutate({
              id: props.id,
              variables: extractPlaceholders(value),
              stepName: step + '-' + level,
              prompt: value,
              ...values,
            })
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <div className="flex flex-col">
              <Select
                label={'Selectionner le niveau ?'}
                onSelect={(e: any) => {
                  setLevel(e as any);
                }}
                items={[
                  {label: 'Maternelle', value: 'maternelle'},
                  {label: 'Primaire', value: 'primaire'},
                  {label: 'CTB', value: 'ctb'},
                  {label: 'Secondaire', value: 'secondaire'},
                ]}
              />

              <Select
                label={'Selectionner le niveau ?'}
                onSelect={(e: any) => {
                  setStep(e as any);
                }}
                items={(level === 'Maternelle' ? ficheStepsMaternelle : level === 'Primaire' ? ficheStepsPrimaire : ficheStepsSecondaire)?.map(e => ({
                  label: e.title,
                  value: e.slug
                }))}
              />

              <textarea ref={textareaRef} value={value} placeholder="Prompt" className="w-full h-[300px] border-[1px] border-[#000000] mt-4 rounded-[15px] p-3" onChange={(e) => {
                // setFieldValue("content", e.target.value);
                setValue(e.target.value);
              }}/>     
      
              <button
                onClick={()=>handleSubmit()}
                className="bg-[#0E9F90] text-[#fff] rounded-[5px] py-2 px-10 w-[150px] mt-8"
              >
                Ajouter
              </button>
            </div>
          )}
      </Formik>
    </div>
  );
};

export default PromptForm;