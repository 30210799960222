import { useLocation, useNavigate } from "react-router-dom";
import { useUserProfile } from "../profile/hooks";
import { useEffect, useState } from "react";
import { Layout } from "../../components/layout";
import { LoadingIcon } from "../../components/loader";
import { useGptForm } from "../situations/mutation";
import { useFicheListQuery } from "../situations/full/hook";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import ClassForm from "./form";
import { IoEyeSharp } from "react-icons/io5";

const Prevision: React.FC = () => {
  const { data: user } = useUserProfile();
  const navigate = useNavigate();
  const [showCreateClass, setShowCreateClass] = useState<boolean>(false);
  const [expandedCards, setExpandedCards] = useState<{ [key: string]: boolean }>({});

  const toggleCard = (id: string) => {
    setExpandedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowCreateClass(false);
  };
  if (showCreateClass) {
    sidePannelView = <ClassForm />;
    sidePannelStyle = "";
    sidePannelTitle = "Créer une nouvelle classe";
  }

  const location = useLocation();
  const course = new URLSearchParams(location.search).get("course");
  const lesson = new URLSearchParams(location.search).get("lesson");
  const areaOfLife = new URLSearchParams(location.search).get("areaOfLife");
  const previousLesson = new URLSearchParams(location.search).get("previousLesson");

  const mutation = useGptForm((_s: string, id: string) => {
    navigate(`/situations/detail?ref=${id}`);
  });

  useEffect(() => {
    const situationInProgress = localStorage.getItem("situationInProgress");

    let situation: any = {};
    if (situationInProgress) {
      situation = JSON.parse(situationInProgress);
    } else if (course && lesson && areaOfLife && previousLesson) {
      situation = {
        course,
        lesson,
        areaOfLife,
        previousLesson,
      };
    }

    if (situation.course) {
      mutation.mutate({
        lessonTitle: situation.lesson,
        subject: situation.course,
        areaOfLife: situation.areaOfLife,
        previousLesson: situation.previousLesson,
        class: "5",
        level: "primaire",
        isFiche: true,
      });
    }
  }, []);

  const { data } = useFicheListQuery(4, 1);

  return (
    <Layout
      activeMenu="Acceuil"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      {mutation.isLoading && (
        <div className="absolute h-full w-full top-0 left-0 bg-primary50/50 flex flex-row justify-center items-center">
          <div className="md:w-[35%]">
            <div className="p-5 rounded-[10px] bg-secondary50 bg-opacity-95 w-full h-full z-50 text-lg text-center flex justify-center items-center flex-col">
              <p className="font-semibold text-[14px]">
                Veuillez patienter, nous traitons votre demande...
              </p>
              <div className="ml-[10px] mt-5">
                <LoadingIcon height={'h-[24px] w-[24px]'} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="px-5 pt-2 sm:pt-4">
        <div className="grid grid-cols-2 sm:grid-cols-1 md:gap-16 sm:gap-8 mb-8">
          <div className="bg-[#0E9F90] py-4 px-8 rounded-[15px] h-fit border-[1px] border-primary50">
            <h2 className="text-[#fff] text-[16px] font-medium uppercase">
              NOMBRE DE CLASSE
            </h2>
            <div className="flex flex-row items-center justify-between mt-3 ">
              <p className="text-[#fff] text-[3rem] font-semibold">
                {data?.[1] || '0'}
              </p>
              {(user?.availableStandaloneMonthlySubscription || 0) > 0 && (
                <button
                  className="h-[40px] px-6 text-[14px] bg-[#fff] rounded-[5px] text-black uppercase border-[1px] border-primary50"
                  onClick={() => {
                    setShowCreateClass(true);
                  }}
                >
                  CREER
                </button>
              )}
            </div>
          </div>

          <div className="border-[0px] py-4 px-8 rounded-[15px] h-fit border-[1px] border-primary50">
            <h2 className="text-[16px] font-medium uppercase">
              NOMBRE D'ECOLE
            </h2>
            <div className="flex flex-row items-center justify-between mt-3">
              <p className="text-[3rem] font-bold">
                {user?.availableStandaloneMonthlySubscription}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-8 md:mt-15 sm:mt-8 px-5">
        <h2 className="text-primary50/80 text-[20px] sm:w-full font-bold uppercase">
          MES ECOLES
        </h2>
      </div>
      <div className="px-5 mt-10">
        <div className="flex grid grid-cols-2 gap-10 mt-10 p-0 md:p-0">
          {data?.[0]?.map((situation) => (
            <div key={situation.id} className="flex flex-col">
              <div className="flex flex-row items-center justify-between bg-[#0E9F90]/30 rounded-[10px] py-4 px-8">
                <p className="text-primary50 font-medium text-[16px] overflow-x-hidden">
                  CS. MAMA MULEZI
                </p>
                {expandedCards[situation.id] ? (
                  <BiSolidDownArrow
                    className="text-primary50 cursor-pointer"
                    onClick={() => toggleCard(situation.id)}
                  />
                ) : (
                  <BiSolidUpArrow
                    className="text-primary50 cursor-pointer"
                    onClick={() => toggleCard(situation.id)}
                  />
                )}
              </div>
              {expandedCards[situation.id] && (
                <div className="mt-1">
                  <ul className="flex flex-col gap-4 bg-[#0E9F90]/10 py-4 px-8 rounded-[10px]">
                    <li className=" flex flex-row items-center justify-between font-semibold text-[18px]">
                    <p>Classe 1</p>
                    <a href="/classes"><IoEyeSharp className="text-primary50 cursor-pointer"/></a>
                    </li>
                    <div className="w-full h-[1px] bg-[#0E9F90]/40"/>
                    <li className="flex flex-row items-center justify-between font-semibold text-[18px]">Classe 2</li>
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Prevision;