export const classes = [
    {
      id: '1',
      option: 'Primary',
      level: '1',
      name: '1ère',
      ageRange: '5 and 9 years old',
      optionName: 'Primaire',
    },
    {
      id: '2',
      option: 'Primary',
      level: '2',
      name: '2ème',
      ageRange: '6 and 10 years old',
      optionName: 'Primaire',
    },
    {
      id: '3',
      option: 'Primary',
      level: '3',
      name: '3ème',
      ageRange: '7 and 11 years old',
      optionName: 'Primaire',
    },
    {
      id: '4',
      option: 'Primary',
      level: '4',
      name: '4ème',
      ageRange: '8 and 12 years old',
      optionName: 'Primaire',
    },
    {
      id: '5',
      option: 'Primary',
      level: '5',
      name: '5ème',
      ageRange: '9 and 13 years old',
      optionName: 'Primaire',
    },
    {
      id: '6',
      option: 'Primary',
      level: '6',
      name: '6ème',
      ageRange: '10 and 14 years old',
      optionName: 'Primaire',
    },
    {
      id: '7',
      option: 'CTB',
      level: '7',
      name: '7ème',
      ageRange: '11 and 15 years old',
      optionName: 'CTB',
    },
    {
      id: '8',
      option: 'CTB',
      name: '8ème',
      level: '8',
      ageRange: '12 and 16 years old',
      optionName: 'CTB',
    },
    {
      id: '9',
      option: 'Secondary',
      name: '1ère',
      level: '1',
      ageRange: '13 and 18 years old',
      optionName: 'Secondaire',
    },
    {
      id: '10',
      option: 'Secondary',
      level: '2',
      name: '2ème',
      ageRange: '13 and 19 years old',
      optionName: 'Secondaire',
    },
    {
      id: '11',
      option: 'Secondary',
      level: '3',
      name: '3ème',
      ageRange: '14 and 21 years old',
      optionName: 'Secondaire',
    },
    {
      id: '12',
      option: 'Secondary',
      level: '4',
      name: '4ème',
      ageRange: '15 and 24 years old',
      optionName: 'Secondaire',
    },
    {
      id: '13',
      option: 'Nursery',
      level: '4',
      name: '1ère',
      ageRange: '2 and 3 years old',
      optionName: 'Maternelle',
    },
    {
      id: '14',
      option: 'Nursery',
      name: '2ème',
      level: '4',
      ageRange: '3 and 5 years old',
      optionName: 'Maternelle',
    },
    {
      id: '15',
      name: '3ème',
      option: 'Nursery',
      level: '4',
      ageRange: '4 and 6 years old',
      optionName: 'Maternelle',
    },
  ];

export const getClasse = (classe: string) => {
    const c = classes.find((c) => classe.toString() === c.id?.toString());
    if(!c) return '';
    return `${c?.name} ${c?.optionName}`
  }