import { useState } from "react"
import { useFicheListQuery } from "./hook"
import { Layout } from "../../../components/layout";
import { useNavigate } from "react-router-dom";
import CreateSituationPage from "..";
import { useUserProfile } from "../../profile/hooks";
import Pagination from "../../../components/pagination";
import { LoadingIcon } from "../../../components/loader";

export const ListFiche = () => {
    const [page, setPage] = useState(1);
    const {data, isLoading, isFetching} = useFicheListQuery(5, page);

    const navigate = useNavigate();

    const [showCreateSituation, setShowCreateSituation] = useState<boolean>(false);
    let closeSidePannel = () => {
      setShowCreateSituation(false);
    }
    let sidePannelView = null;
  
    if (showCreateSituation) {
      sidePannelView = <CreateSituationPage isFiche  title={'Créer une fiche'} />
    }
    const handlePageChange = ({ selected }: { selected: number }) => {
        setPage(selected + 1);
    };
    const totalPages = data ? Math.ceil(data?.[1] / 5) : 0;

    const { data: user } = useUserProfile();

    return (
        <Layout activeMenu='Mes Fiches' sidePannelView={sidePannelView} sidePannelOnclose={closeSidePannel} sidePannelTitle="Créer une fiche">
            <div className='flex flex-row justify-between items-center  p-5 md:p-0' >
                <h1 className="font-bold text-[18px] "> Liste de fiches</h1>
                {(user?.availableStandaloneMonthlySubscription || 0) > 0 && <button onClick={() => setShowCreateSituation(true)} className='bg-[#000] px-3 py-0 text-[#fff] text-[14px] h-[35px] rounded-md'>Créer fiche</button>}
            </div>

            <div className="flex flex-col gap-5 mt-10  p-5 md:p-0">
                {(isLoading || isFetching) && (
                    <LoadingIcon />
                )}
                {data?.[0]?.map(situation => <div key={situation.id} className={`bg-[#EDE7E5] rounded-[15px] p-5`}>
                <div className="flex flex-row gap-5">
                <p className="bg-[#EFDBDB] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px] h-[30px] overflow-y-hidden">
                    {situation?.subject}
                </p>

                <p className="bg-[#868686] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                    {situation?.areaOfLife}
                </p>

                <button
                    className="ml-auto bg-primary50 rounded-[5px] px-3 py-1 text-[12px] text-[#fff] cursor-pointer"
                    onClick={() => {
                        navigate(`/fiches/${situation.id}`);
                    }}
                >
                    VOIR
                </button>
                </div>
                <p className="text-primary-900 font-semibold text-[14px] text-left my-4 overflow-x-hidden">
                {situation.lessonTitle}
                </p>
                <p className="max-h-[50px]  text-ellipsis overflow-y-hidden ">
                {situation?.objectives}
                </p>
                </div>)}
            </div>
            {data && (
                <Pagination
                    pagesCount={totalPages} // Assuming totalPages is available in your response data
                    currentPage={page - 1} // Adjusting page number to start from 0
                    onPageChange={handlePageChange}
                />
            )}
        </Layout>
    )
}