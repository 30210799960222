import React from 'react';
import '../button/animation.css';

type PropTypes = {
  backgroundColor?: string;
  height?: number | string;
};

export const LoadingIcon: React.FC<PropTypes> = ({height}) => {
 
  return (
    <span className={`relative flex w-[30px] ${!height ? 'h-[30px]' : height} `}>
  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary50 opacity-75"></span>
  <span className={`relative inline-flex rounded-full w-[30px] bg-primary50 ${!height ? 'h-[30px]' : height} `}></span>
</span>
  )
};