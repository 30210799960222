import React, { FC, useContext } from "react";
import logo from "../../assets/dugassistant.png";
import { Link } from "react-router-dom";
import {
  BiHomeAlt,
  BiBookAlt,
  BiUser,
  BiMenu,
  BiChevronLeft,
} from "react-icons/bi";
import { MdOutlineAttachMoney } from "react-icons/md";
import Button from "../button";
import { logout } from "../../utils/auth";
import { UserContext } from "../../context/user.context";

interface SideBarProps {
  mobileOpenned: boolean;
  toogleMobileSidebar: () => void;
  activeMenu?: string;
}

interface MobileSideBarProps {
  toogleMobileSidebar: () => void;
}

const menus = [
  {
    name: "Acceuil",
    icon: <BiHomeAlt size={18} color="text-primary50" />,
    link: "/",
  },
  {
    name: "Mes Fiches",
    icon: <BiBookAlt size={18} color="text-[#0E9F90]" />,
    link: "/fiches",
  },
  // {
  //   name: "Mes previsions",
  //   icon: <BsBuildings size={18} color="text-[#0E9F90]" />,
  //   link: "/previsions",
  // },
  // {
  //   name: "Mes enseignants",
  //   icon: <BsBuildings size={18} color="text-[#0E9F90]" />,
  //   link: "/teachers",
  // },
  // {
  //   name: "Mes Ressources",
  //   icon: <SlBookOpen size={18} color="text-[#0E9F90]" />,
  //   link: "/ressources",
  // },
  {
    name: "Paiements",
    icon: (
      <MdOutlineAttachMoney
        size={18}
        color="text-primary50"
        className="ml-[-3px]"
      />
    ),
    link: "/payments",
  },
  {
    name: "Profile",
    icon: <BiUser size={18} color="text-primary50" />,
    link: "/profile",
  },
];

const Sidebar: FC<SideBarProps> = ({
  toogleMobileSidebar,
  mobileOpenned,
  activeMenu = "Acceuil",
}) => {
  const user = useContext(UserContext);

  return (
    <div
      className={`p-8 bg-[#FFEAD6]  fixed flex flex-col z-[100] h-screen justify-between min-w-[250px] sm:fixed shadow-md  ${
        mobileOpenned ? "sm:visible" : "sm:hidden"
      }`}
    >
      <div className="">
        <div className="flex flex-row sm:justify-between items-center">
          <img src={logo} width={60} className="rounded-[50px] sm:hidden" />
          <div className="px-3 bg-primary50 rounded-[5px]">
            <BiChevronLeft
              size={30}
              color="text-[#fff]"
              className="md:hidden  text-[#fff]"
              onClick={() => {
                toogleMobileSidebar();
              }}
            />
          </div>
        </div>
        <ul className="mt-16 flex flex-col gap-8">
          {[...menus, ...(user?.role?.name?.toLocaleLowerCase() === 'admin' ? [  {
    name: "Assistants",
    icon: <BiUser size={18} color="text-primary50" />,
    link: "/assistants",
  },
  {
    name: "Fichier",
    icon: <BiUser size={18} color="text-primary50" />,
    link: "/assistant-fichiers",
  },] : [])].map((menu) => (
            <li
              className={`${
                menu.name === activeMenu
                  ? "bg-[#000] bg-opacity-10 rounded-[5px] p-2"
                  : "px-2"
              } `}
            >
              <Link
                to={menu.link || "#"}
                className="flex flex-row gap-2 items-center"
              >
                {menu.icon}
                <p className="text-[16px]">{menu.name}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <Button
          label={"Deconnexion"}
          onClick={() => {
            logout();
          }}
          width="min-w-[195px] text-[14px] rounded-[7px] ml-[-0px] mt-auto "
          borderRadius="10px"
          height="h-[35px]"
        />
      </div>
    </div>
  );
};

export const MobileSidebar: FC<MobileSideBarProps> = (props) => {
  return (
    <div className="bg-[#FFF8F6] h-[50px] md:hidden w-full px-4 pt-4 fixed z-10">
      <div className="flex flex-row items-center gap-3">
        <BiMenu
          size={25}
          color="text-primary50"
          onClick={() => {
            props.toogleMobileSidebar();
          }}
        />
        <h1 className="text-sm font-bold">Dug Assistant</h1>
      </div>
    </div>
  );
};

export default Sidebar;
