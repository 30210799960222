import { Layout } from "../../../components/layout";
import CreateSituationPage from "..";
import { useState } from "react";
import { Situation, useSituationList } from "../fetchdata";
import { BiExport } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { useGptForm } from "../mutation";
import { LoadingIcon } from "../../../components/loader";

const tabActive =
  "px-5 py-1 text-[#fff] bg-[#0E9F90] font-medium rounded-[8px] text-[14px] w-fit cursor-pointer";
const tabInactive =
  "px-5 py-1 text-[#000]  bg-transparent font-semibold text-[14px] cursor-pointer ";

const tabs = ["Situation", "Exercices", "Atelier"];
const SituationDetailPage: React.FC = () => {
  const situations = useSituationList();
  const location = useLocation();
  const ref = new URLSearchParams(location.search).get("ref");
  const situation = situations?.data?.find?.((s) => s.id === ref);
  const mutation = useGptForm((res: any) => {
    // console.log(res);
  });

  const [showCreateSituation, setShowCreateSituation] =
    useState<boolean>(false);
  const [selectedSituation, setSelectedSituation] = useState<Situation>();
  let closeSidePannel = () => {
    setShowCreateSituation(false);
    setSelectedSituation(undefined);
  };
  let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";

  if (showCreateSituation || selectedSituation) {
    sidePannelView = (
      <CreateSituationPage
        result={selectedSituation?.situation}
        title={selectedSituation?.lessonTitle}
      />
    );
    sidePannelStyle = "";
    sidePannelTitle = selectedSituation
      ? "Fiche enregistrée"
      : "Créer une nouvelle fiche";
  }

  const [activeTab, setActiveTab] = useState(0);
  const generatePDF = async (
    result: string,
    title: string,
    exercice?: string,
    activity?: string
  ) => {
  };

  return (
    <Layout
      activeMenu="Mes Situations"
      sidePannelView={sidePannelView}
      sidePannelOnclose={closeSidePannel}
      sidePannelStyle={sidePannelStyle}
      sidePannelTitle={sidePannelTitle}
    >
      <div className="h-[100vh] px-5">
        <div className="flex flex-row items-center justify-between">
          <h2 className="text-primary50/80 md:text-[1.3rem] sm:text-[1.1rem] font-bold ">
            {situation?.lessonTitle}
          </h2>
          <button
            onClick={() =>
              generatePDF(
                situation?.situation || "",
                situation?.lessonTitle || "",
                situation?.exercice,
                situation?.activity
              )
            }
            className="border-[#0E9F90] rounded-[5px] border-[1px] text-[#0E9F90] px-4 py-1 sm:hidden"
          >
            Exporter en fichier
          </button>
          <BiExport
            onClick={() =>
              generatePDF(
                situation?.situation || "",
                situation?.lessonTitle || "",
                situation?.exercice,
                situation?.activity
              )
            }
            size={30}
            className="w-[30px] md:hidden"
          />
        </div>
        <div className="flex flex-row gap-5 mt-8 border-b-[2px] border-b-[#D9D9D9] md:w-fit pb-4 overflow-x-scroll sm:w-[100%]">
          {tabs.map((tab, index) => (
            <h2
              className={index === activeTab ? tabActive : tabInactive}
              onClick={() => {
                setActiveTab(index);
              }}
            >
              {tabs[index]}
            </h2>
          ))}
        </div>
        {activeTab === 0 && (
          <div className={`bg-[#EDE7E5] rounded-[15px] p-8 mt-10 `}>
            <div className="flex flex-row gap-5">
              <p className="bg-[#EFDBDB] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                {situation?.course}
              </p>

              <p className="bg-[#FFE4CA] px-4 py-1 rounded-[5px] uppercase font-medium text-[14px]">
                {situation?.areaOfLife}
              </p>
            </div>
            {/* <p className="mt-4 text-justify whitespace-pre-wrap snap-none">
            {  htmlToReactParser.parse(situation?.situation?.replaceAll('[-', '<b>').replaceAll('-]', '</b>'))}
            </p> */}
          </div>
        )}
        {activeTab === 1 && (
          <div className="mt-5">
            {!situation?.exercice ? (
              <button
                onClick={() => {
                  if (!mutation.isLoading)
                    mutation.mutate({
                      ...situation,
                      type: "exercice",
                      parentId: situation?.id,
                      subject: situation?.course,
                    });
                }}
                className="border-[#0E9F90] rounded-[5px] border-[1px] text-[#0E9F90] px-4 py-1"
              >
                Generer un exercise{" "}
                {mutation.isLoading && <LoadingIcon height={'h-[15px] w-[15px]'} />}
              </button>
            ) : (
              <p className="mt-4 text-justify whitespace-pre-wrap snap-none bg-[#EDE7E5] rounded-[15px] p-8 mt-10 ">
                {situation?.exercice}
              </p>
            )}
          </div>
        )}
        {activeTab === 2 && (
          <div className="mt-5">
            {!situation?.activity ? (
              <button
                onClick={() => {
                  if (!mutation.isLoading)
                    mutation.mutate({
                      ...situation,
                      type: "activity",
                      parentId: situation?.id,
                      subject: situation?.course,
                    });
                }}
                className="border-[#0E9F90] rounded-[5px] border-[1px] text-[#0E9F90] px-4 py-1"
              >
                Generer une activite{" "}
                {mutation.isLoading && <LoadingIcon height={'h-[15px] w-[15px]'} />}
              </button>
            ) : (
              <p className="mt-4 text-justify whitespace-pre-wrap snap-none bg-[#EDE7E5] rounded-[15px] p-8 mt-10 ">
                {situation?.activity}
              </p>
            )}
          </div>
        )}
        {/* {activeTab === 3 && (
          <Fiche ficheText={situation?.situation}/>
        )} */}
      </div>
    </Layout>
  );
};

export default SituationDetailPage;
