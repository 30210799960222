import Input from "../../../components/input";
import { Formik } from "formik";
import Select from "../../../components/inputDropdown";
import { getAllCourses } from "../../../utils/sections";
import { useState } from "react";
import InputDropdown from "../../../components/inputDropdown";
import { Assistant } from "../../payment/fetch";
import { useAssistantMutationForm } from "./mutation";
import { LoadingIcon } from "../../../components/loader";

const AssistantForm: React.FC<{assistant?: Assistant, onSuccess?: (id: string) => void}> = (props) => {
  const [level, setLevel] = useState<string[]>([]);
  const allCourses = getAllCourses();

  const courses = [
    ...(level.includes('secondaire') ? allCourses.map(c => ({value: c, label: c})) : [] as Array<{label: string, value: string}>),
    ...[
    {label: 'Exploratoire', level: ['maternelle'], subdomain: ["Decouverte de l'environnement", 'Eveil scientifique']},
    {label: 'Language', level: ['maternelle'], subdomain: ['Recitation, Conte, Saynette', 'Pre-lecture', 'Elocution']},
    {label: 'Mathematiques', level: ['maternelle'], subdomain: ['Nombre & Sens des operations']},
    {label: 'Sensorielle', level: ['maternelle'], subdomain: ['Excercice tactil', 'Exercice visuel', 'Excercice olfactif', 'Exercice gustatif', 'Exercice auditif', 'Exercice kinesthesique']},
    {label: 'Vie pratique', level: ['maternelle'], subdomain: ['Jeux de metier', 'Jeux menager']},
    {label: 'Comportement', level: ['maternelle'], subdomain: ['Education civique', 'Education morale', 'Education Religieuse']},
    {label: 'Promotion de la sante', level: ['maternelle'], subdomain: ['Education a la sante', "Education a l'hygienne", 'Education a la nutrition']},
    {label: 'Musicale', level: ['maternelle'], subdomain: ['Chant', 'Orchestration', 'Ronde']},
    {label: 'Physique', level: ['maternelle'], subdomain: ['Gymnastique']},
    {label: 'Psychomotrice', level: ['maternelle'], subdomain: ['Schema corporel', 'Structuration spatiale', 'Orientation temporelle', 'Lateral']},
    {label: 'Art plastiques', level: ['maternelle'], subdomain: ['Graphique', 'Manuel']},
    {label: 'Libre (Jeux dans les coins)', level: ['maternelle'], subdomain: ['Chambre', 'Bureau', 'Garage', 'Cuisine']},
    
    {label: 'Anatomie', level: ['primaire', 'ctb']},
    {label: 'Anglais', level: ['maternelle','primaire', 'ctb', 'secondaire']},
    {label: 'Biologie', level: ['secondaire']},
    {label: 'Botanique', level: ['primaire', 'ctb']},
    {label: 'Chimie', level: ['secondaire']},
    {label: 'Comptabilité', level: ['secondaire']},
    {label: 'Dessin', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Économie', level: ['secondaire']},
    {label: 'Education A la vie', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation civique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation physique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Éducation religieuse', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Études environnementales', value: 'Études environnementales'},
    {label: 'Études sociales', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Français', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Conjugaison', 'Grammaire', 'Litterature', 'Orthographe', 'Phonétique', 'Redaction', 'Vocabulaire']},
    {label: 'Géographie', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Histoire', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Informatique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Infographie', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Design - Communication visuelle', 'Design - Graphisme']},
    {label: 'Kiswahili', level: ['primaire'], subdomain: ['Usemi', 'Usomi']},
    {label: 'Leadership', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Mathématiques', level: ['primaire', 'ctb', 'secondaire'], subdomain: ['Algèbre', 'Analyse', 'Arithmétique', 'Géométrie', 'Logique', 'Statistiques', 'Probabilités']},
    {label: 'Musique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'organisation des entreprises', level: ['secondaire']},
    {label: 'Physique', level: ['ctb', 'secondaire']},
    {label: 'Sciences Physique', level: ['primaire', 'ctb', 'secondaire']},
    {label: 'Secourisme', level: ['maternelle','primaire', 'ctb', 'secondaire']},
    {label: 'Zoologie', level: ['primaire', 'ctb', 'secondaire']},
  ].sort((a, b) => a.label.localeCompare(b.label)).map(course => ({label: course.label, value: course.label}))]
  
  const uniqueCourses = courses.filter(
    (course, index, self) =>
      index === self.findIndex((c) => c.label === course.label)
  );

  const mutation = useAssistantMutationForm( () => {
    props.onSuccess?.('');
  });
  
  return (
    <div>
      <Formik
        initialValues={{...props.assistant}}
          onSubmit={(values: any) => {
            mutation.mutate({
              ...values,
              id: props.assistant?.id
            })
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <div className="flex flex-col">
              <Input label="Name" placeholder={""} name="name" defaultValue={props.assistant?.name} onChange={(e:any) => {
                setFieldValue("name", e);
              }} />

              <InputDropdown
                label={'Selectionner le niveau ?'}
                onSelect={(e: any) => {
                  console.log(e);
                  setFieldValue('levels', e?.join?.(','));
                  setLevel(e as any);
                }}
                items={[
                  {label: 'Maternelle', value: 'maternelle'},
                  {label: 'Primaire', value: 'primaire'},
                  {label: 'CTB', value: 'ctb'},
                  {label: 'Secondaire', value: 'secondaire'},
                ]}
                isMulti
                selectedItem={props?.assistant?.levels?.split(',')}
                />

              <InputDropdown
                label={'Cours'}
                onSelect={(e) => {
                  setFieldValue('courses', e);
                }}
                items={uniqueCourses}
                isMulti
                selectedItem={props?.assistant?.courses}
              />   
      
              <button
                onClick={()=>handleSubmit()}
                className="bg-[#0E9F90] text-[#fff] rounded-[5px] py-2 px-10 w-[150px] mt-8"
              >
                {props.assistant ? 'Edit' : 'Add'}
                {mutation.isLoading && (
                  <LoadingIcon height={20} />
                )}
              </button>
            </div>
          )}
      </Formik>
    </div>
  );
};

export default AssistantForm;