import { useState } from 'react';
import InputDropdown from '../../components/inputDropdown';
import { prices } from '../../utils/prices';
import PriceForm from './steps/step1';

export const payAsYouGoOptions = [
  { price: 2.99, value: 8, cdf: 8522 },
  { price: 5.99, value: 17, cdf: 17072 },
  { price: 8.99, value: 25, cdf: 25642 },
  { price: 9.99, value: 28, cdf: 28485 },
  { price: 11.99, value: 34, cdf: 34192 },
  { price: 24.99, value: 70, cdf: 71265 },
  { price: 49.99, value: 140, cdf: 142465 },
  { price: 99.99, value: 281, cdf: 284965 },
];

export const PricingV2: React.FC<{paymentData?: any; onClose?: any}> = ({paymentData, onClose}) => {
  const [selectedPrice, setSelectedPrice] = useState<string>('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const [selectedPayment, setSelectedPayment] = useState<any>('');

  const handleSelect = (item: string) => {
    setSelectedPrice(item);
  };

  const handlePurchaseClick = (paymentData: any) => {
    setShowPaymentForm(true);
    setSelectedPayment(paymentData);
  };

  return (
    <div id="pricing" className="bg-white p-4">
      { showPaymentForm || paymentData ? (
        <PriceForm paymentData={selectedPayment || paymentData} onClose={() => {
          setShowPaymentForm(false);
          onClose?.();
        }} paymentDataText={(selectedPayment || paymentData).paymentType === 'SUBSCRIPTION' ? `${(selectedPayment || paymentData).subscriptionLength} mois à ${(selectedPayment || paymentData)?.price} $` :  `${(selectedPayment || paymentData).tokenPurchased} fiches à ${(selectedPayment || paymentData).price} $`} paymentText={(selectedPayment || paymentData).paymentType === 'SUBSCRIPTION' ?  'Abonnement pour ' : 'Achat '}/>
      ) : (
        <div className="md:grid flex flex-col-reverse md:grid-cols-3  gap-16">
          <div className="border-primary50/20 flex flex-col justify-between rounded-[5px] border-[2px] p-6 shadow-sm">

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2 pt-4">
                <h2 className="text-center text-[40px] font-bold text-primary-500">
                   ${selectedPrice || '0'}
                </h2>
                <p className="text-center text-[24px] font-semibold text-primary-400">
                  {selectedPrice ? (payAsYouGoOptions.find(e => e.price.toString() === selectedPrice)?.value || 0): '0'} fiches
                </p>
              </div>
              <form className="flex w-full flex-col gap-4">
                <InputDropdown
                  label="Selectionner un budget pour l'achat à l'unité"
                  items={payAsYouGoOptions.map((item) => ({
                    label: `${item.price} $ pour ${item.value} fiches`,
                    value: item.price.toString(),
                  }))}
                  onSelect={handleSelect}
                  inputStyle="border border-primary-400/80 rounded-[14px]"
                />
              </form>
            </div>
            <button
              className="mt-5 rounded-lg bg-primary-500 px-4 py-2 font-semibold text-white md:w-[40%]"
              onClick={() => {
                if (!selectedPrice || !payAsYouGoOptions.find(e => e.price.toString() === selectedPrice)) {
                  return;
                }
                handlePurchaseClick({
                  tokenPurchased: payAsYouGoOptions.find(e => e.price.toString() === selectedPrice)?.value,
                  paymentType: 'PAY_AS_YOU_GO',
                  price: selectedPrice,
                });
              }}
            >
              Acheter
            </button>
          </div>
          {prices.map((p, index) => (
            <div
              key={p.id}
              className={`relative flex flex-col justify-between rounded-[5px] p-6 ${
                index === prices.length - 2
                  ? 'border-[4px] border-primary-500 shadow-lg'
                  : 'border-primary border-[2px] shadow-sm'
              }
              ${
                index === prices.length - 1
                  ? 'bg-primary-500 text-white shadow-lg'
                  : 'border-primary-500 border-[2px] shadow-sm'
              }`}
            >
              {p.name && (
                <div
                  className={`absolute left-1/2 top-[-16px] w-full -translate-x-1/2 text-center`}
                >
                  <span
                    className={`rounded-[5px] p-2 px-8 text-[16px]  font-medium ${
                      index === prices.length - 1
                        ? 'border-[1px] border-primary-500 bg-primary-400 text-[#fff]'
                        : 'bg-primary-500 text-white '
                    }`}
                  >
                    {p.name}
                  </span>
                </div>
              )}
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-2 pt-4">
                  <h2
                    className={`text-center text-[40px] font-bold ${
                      index === prices.length - 1
                        ? 'text-primary-400'
                        : 'text-primary-500 '
                    }`}
                  >
                    {p.price} <span className='text-[14px]'>{p.frequency}</span>
                  </h2>
                  <p className="text-center text-[24px] font-semibold text-primary-400">
                    {p.fiche} fiches
                  </p>
                </div>
                <ul className="flex flex-col gap-6">
                  {p.list.map((item, idx) => (
                    <li key={idx} className="flex flex-row gap-2 text-[14px]">
                      <svg
                        className={`h-5 w-5 shrink-0 dark:text-green-400 ${
                          index === prices.length - 1
                            ? 'text-primary-400'
                            : 'text-primary-500 '
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <p dangerouslySetInnerHTML={{__html: item}} />
                    </li>
                  ))}
                </ul>
              </div>
              <button
                className={`mt-5 rounded-lg px-4 py-2 font-semibold md:w-[40%] ${
                  index === prices.length - 1
                    ? 'bg-primary-400 text-primary-500'
                    : 'bg-primary-500 text-white'
                }`}
                onClick={() => {
                  handlePurchaseClick({
                    subscriptionLength: p.selectedLength,
                    paymentType: 'SUBSCRIPTION',
                    price: Number.parseFloat((p.price?.replace('$', ''))) * p.selectedLength
                  });
                }}
              >
                Acheter
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};