import { io, Socket } from 'socket.io-client';

export class SocketService {
  private socket: Socket | null = null;
  private token: string | null = null;

  constructor() {
    this.token = localStorage.getItem('token'); // Or however you store your JWT
  }

  connect() {
    // Use environment variable or fallback
    const backendUrl = process.env.API_URL || 'https://bot.harvely.com';
    
    this.socket = io(backendUrl, {
      auth: {
        token: `Bearer ${this.token}`
      }
    });

    this.socket.on('connect', () => {
      console.log('Connected to WebSocket server');
    });

    this.socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    return this.socket;
  }

  subscribeFiche(ficheId: string) {
    if (!this.socket) return;
    console.log(`Subscribing to fiche: ${ficheId}`);
    this.socket.emit('subscribe-fiche', ficheId);
  }

  unsubscribeFiche(ficheId: string) {
    if (!this.socket) return;
    console.log(`Unsubscribing from fiche: ${ficheId}`);
    this.socket.emit('unsubscribe-fiche', ficheId);
  }

  onStepCompleted(callback: (data: any) => void) {
    if (!this.socket) return;
    this.socket.on('fiche-step-completed', (data) => {
      console.log('Step completed:', data);
      callback(data);
    });
  }

  onImageStarted(callback: (data: any) => void) {
    if (!this.socket) return;
    this.socket.on('fiche-image-started', (data) => {
      console.log('Image generation started:', data);
      callback(data);
    });
  }

  onImageCompleted(callback: (data: any) => void) {
    if (!this.socket) return;
    this.socket.on('fiche-image-completed', (data) => {
      console.log('Image generation completed:', data);
      callback(data);
    });
  }

  onError(callback: (data: any) => void) {
    if (!this.socket) return;
    this.socket.on('fiche-error', (data) => {
      console.error('Error:', data);
      callback(data);
    });
    this.socket.on('fiche-image-error', (data) => {
      console.error('Image error:', data);
      callback(data);
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
} 