export const requestNotificationPermission = async () => {
    if (!('Notification' in window)) {
        console.error('This browser does not support notifications.');
        return;
    }

    if (Notification.permission === 'default') {
        await Notification.requestPermission();
    }
};

export const sendNotification = (title: string, options?: NotificationOptions) => {
    if (Notification.permission === 'granted') {
        new Notification(title, options);
    }
}; 