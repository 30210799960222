import { useState } from "react";
import { Layout } from "../../components/layout";
import { FiFileText } from "react-icons/fi";
import PromptForm from "./forms/prompt";
import { useAssistantSingle } from "../payment/fetch";
import { useLocation, useParams } from "react-router-dom";

const AdminPrompt: React.FC = () => {
  const params = useParams()
  const id = params.id || '';
  const {data, isLoading} = useAssistantSingle(id);

  const [showCreatePrompt, setShowCreatePrompt] =
    useState<boolean>(false);
    let sidePannelView = null;
  let sidePannelStyle = "";
  let sidePannelTitle = "";
  let closeSidePannel = () => {
    setShowCreatePrompt(false);
  };

  if (showCreatePrompt) {
    sidePannelView = <PromptForm assistant={data!} id={id} />;
    sidePannelStyle = "w-[35%]";
    sidePannelTitle = "Prompt";
  }
  return (
    <Layout activeMenu="Acceuil"     sidePannelView={sidePannelView}
    sidePannelOnclose={closeSidePannel}
    sidePannelStyle={sidePannelStyle}
    sidePannelTitle={sidePannelTitle}>
       <div className="p-8">
       <h1 className="font-bold text-[18px] ">Assistant : {data?.name}</h1>
       <h2 className="text-[15px] my-2"><span className="font-bold">Cours : </span>{data?.courses?.join(',')}</h2>
       <h2 className="text-[15px] my-2"><span className="font-bold">Niveaux:</span> {data?.levels}</h2>

       <div className="mt-[32px]">
       <button 
                  className="bg-[#0E9F90] text-[#FFF] px-6 py-3 rounded-md flex flex-row items-center justify-center gap-2"
                  onClick={() => {setShowCreatePrompt(true)}}
                  >
                    <FiFileText className="text-[24px]"/>
                    Nouveau prompt
                  </button>
       </div>


       <div className=" w-[100%] mt-10">
      <table className="max-h-[60%] overflow-auto mt-6 w-full">
        <thead className="mx-5 text-blue w-full bg-yellow">
          <tr className="w-full">
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Step
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Level
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Prompt
            </th>
            <th className="text-[14px] text-primary50 font-semibold text-left pl-5 py-4">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
            {Object.keys(data?.prompts || {}).map(prompt => (
              <tr
              className=" border-t-[16px] border-[#fff] bg-[#0E9F90]/20"
            >
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {prompt?.split('-')[0]}
              </td>
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                {prompt?.split('-')[1] || ''}
              </td>
              <td className="text-primary-900 text-sm text-left pl-5 py-4 overflow-x-hidden w-[60%]">
                <p>{data?.prompts?.[prompt].prompt}</p>
              </td>
              
              <td className="text-primary-900 font-semibold text-sm text-left pl-5 py-4 overflow-x-hidden">
                  <div className="flex flex-row items-center gap-6">                   
                  <button 
                  className="border-[2px] border-[#0E9F90] px-4 py-1 rounded-md flex flex-row items-center justify-center gap-2">
                    <FiFileText className="text-[20px] text-[#0E9F90]"/>
                    Edit
                  </button>
                  </div>
              </td>
            </tr>))}
        </tbody>
      </table>
      </div>
       </div>
    </Layout>
  );
};

export default AdminPrompt;
