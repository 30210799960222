import React, { useEffect, useState } from 'react';
import { Layout } from '../../../components/layout';
import { useFicheQuery } from './hook';
import { useLocation } from 'react-router-dom';
import { LoadingIcon } from '../../../components/loader';
import { FicheStep, getSteps } from '../../../utils/fiche';
import removemd from 'remove-markdown';
import { SocketService } from '../../../services/socket.service';
import { getClasse } from '../../../utils/classe-utils';
// Import the detail components
import { DetailFicheSecondaire } from './detail';
import { DetailFichePrimaire } from './detailPrimaire';
import { DetailFicheMaternelle } from './detailMaternelle';
// Import KaTeX for LaTeX rendering
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import Modal from 'react-modal'; // Import a modal library
import { requestNotificationPermission, sendNotification } from '../../../utils/notification';
import { FeedbackDialog } from '../../../components/FeedbackDialog';
import { useFeedback } from '../../../hooks/useFeedback';
import { useUserProfile } from '../../profile/hooks';

export const CreateFicheTimeline = () => {
    const id = useLocation().pathname.substring(useLocation().pathname.lastIndexOf('/') + 1).split('?')[0];
    const { data, isLoading, refetch } = useFicheQuery(id);
    const ficheSteps = getSteps(data);
    
    // Socket integration for real-time updates
    const [socketService] = useState(new SocketService());
    const [stepStatus, setStepStatus] = useState<{[key: number]: 'pending' | 'in-progress' | 'completed'}>({});
    const [stepData, setStepData] = useState<{[key: number]: string}>({});
    const [imageStatus, setImageStatus] = useState<'idle' | 'generating' | 'completed' | 'error'>('idle');
    const [activeStep, setActiveStep] = useState<number | null>(null);
    const [isComplete, setIsComplete] = useState(false);
    
    // Add refs for step elements to enable scrolling
    const stepRefs = React.useRef<{[key: number]: HTMLDivElement | null}>({});
    
    // Add state for showing detail preview
    const [showDetail, setShowDetail] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    
    // Add state for feedback dialog
    const [showFeedback, setShowFeedback] = useState(false);
    // const { data: feedback } = useFeedback(id);
    const { data: profile } = useUserProfile();
    
    // Determine which detail component to use based on class/grade level
    const DetailFiche = Number.parseInt((data?.classe || '1'), 10) < 7 
        ? DetailFichePrimaire 
        : Number.parseInt((data?.classe || '1'), 10) < 13 
            ? DetailFicheSecondaire 
            : DetailFicheMaternelle;
    
    // Track expanded/collapsed state of each step
    const [expandedSteps, setExpandedSteps] = useState<{[key: number]: boolean}>({});
    
    // Initialize step data and status
    useEffect(() => {
        if (!data) return;
        
        const stepDataObj: {[key: number]: string} = {};
        const statusObj: {[key: number]: 'pending' | 'in-progress' | 'completed'} = {};
        const expandedObj: {[key: number]: boolean} = {};
        
        ficheSteps.forEach((step, index) => {
            const stepNumber = index + 1;
            stepDataObj[stepNumber] = data[step.slug] || '';
            
            if (stepNumber < (data.currentStep || 1) || data[step.slug]) {
                statusObj[stepNumber] = 'completed';
                // expandedObj[stepNumber] = index === 1; // Expand the second step by default as in the design
            } else if (stepNumber === (data.currentStep + 1)) {
                statusObj[stepNumber] = 'in-progress';
                expandedObj[stepNumber] = false; // Don't expand current step by default
                setActiveStep(stepNumber);
            } else {
                statusObj[stepNumber] = 'pending';
                expandedObj[stepNumber] = false;
            }
        });
        
        setStepData(stepDataObj);
        setStepStatus(statusObj);
        setExpandedSteps(expandedObj);
        
        if (data.currentStep > ficheSteps.length) {
            setIsComplete(true);
        }
    }, [data]);

    const [initialStep, setInitialStep] = useState(true);
    
    // Socket connection for real-time updates
    useEffect(() => {
        if (!id) return;
        
        const socket = socketService.connect();
        socketService.subscribeFiche(id);
        
        socketService.onStepCompleted((data) => {
            // Update step status
            setStepStatus(prev => ({
                ...prev, 
                [data.step]: 'completed', 
                [data.step + 1]: 'in-progress'
            }));
            
            // Update step data
            setStepData(prev => ({
                ...prev,
                [data.step]: data.content || prev[data.step]
            }));

            refetch();
            
        });
        
        socketService.onImageStarted(() => {
            setImageStatus('generating');
        });
        
        socketService.onImageCompleted((data) => {
            setImageStatus('completed');
            refetch();
            handleLastStepCompleted();
        });
        
        socketService.onError((error) => {
            console.error("Error in fiche generation:", error);
            if (error.type === 'image') {
                setImageStatus('error');
            }
        });
        
        return () => {
            socketService.unsubscribeFiche(id);
            socketService.disconnect();
        };
    }, [id]);

    useEffect(() => {
        if (data?.currentStep && !initialStep) {
            setExpandedSteps(prev => ({
                ...prev,
                [data.currentStep]: true,
            }));
            setActiveStep(data.currentStep);
            
            // Scroll to the active step with a slight delay to ensure DOM is updated
            setTimeout(() => {
                if (stepRefs.current[data.currentStep]) {
                    stepRefs.current[data.currentStep]?.scrollIntoView({ 
                        behavior: 'smooth', 
                        block: 'center' 
                    });
                }
            }, 300);
        } else if (data?.currentStep && initialStep) {
            setInitialStep(true);
        }
    }, [data?.currentStep])
    
    useEffect(() => {
        if (data && typeof data === 'object' && 'error' in data && (data as any).error === 'token') {
            window.location.replace('/login');
        }
    }, [data]);

    useEffect(() => {
        requestNotificationPermission();
    }, []);
    
    const handleLastStepCompleted = () => {
        sendNotification('Fiche Completed', {
            body: 'The last step of your fiche has been generated successfully!',
            //icon: '/path/to/icon.png', // Optional: Add an icon
        });
    };

    // Function to handle print
    const handlePrint = () => {
        window.print();
    };
    
    // Check if a step includes image generation
    const hasImages = (step: number) => {
        const stepSlug = ficheSteps[step - 1]?.slug;
        return stepSlug === FicheStep.SyntheseStudent;
    };
    
    // Toggle expansion of a step
    const toggleStepExpansion = (stepNumber: number) => {
        setExpandedSteps(prev => ({
            ...prev,
            [stepNumber]: !prev[stepNumber]
        }));
    };
    
    // Render step status icon
    const renderStepIcon = (status: 'pending' | 'in-progress' | 'completed', stepNumber: number) => {
        if (status === 'in-progress') {
            return (
                <div className="w-6 h-6 rounded-full border border-blue-600 flex items-center justify-center">
                    <LoadingIcon />
                </div>
            );
        } else if (status === 'completed') {
            return (
                <div className="w-6 h-6 rounded-full flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                </div>
            );
        } else {
            return (
                <div className="w-6 h-6 rounded-full border border-gray-400 flex items-center justify-center">
                </div>
            );
        }
    };
    
    // Helper function to detect and render LaTeX
    const renderContent = (content: string) => {
        if (!content) return null;
        
        // Regular expressions to detect LaTeX expressions
        const blockLatexRegex = /\$\$(.*?)\$\$/g;
        const inlineLatexRegex = /\$(.*?)\$/g;
        
        // First, split by block LaTeX expressions
        const blockParts = content.split(blockLatexRegex);
        
        if (blockParts.length === 1 && !blockLatexRegex.test(content)) {
            // No block LaTeX, check for inline LaTeX
            const inlineParts = content.split(inlineLatexRegex);
            
            if (inlineParts.length === 1 && !inlineLatexRegex.test(content)) {
                // No LaTeX at all, just return the content
                return content;
            }
            
            // Extract inline LaTeX expressions
            const inlineLatexMatches = content.match(inlineLatexRegex) || [];
            const inlineLatex = inlineLatexMatches.map(match => match.slice(1, -1));
            
            // Render with inline LaTeX
            return (
                <>
                    {inlineParts.map((part, index) => (
                        <React.Fragment key={index}>
                            {part}
                            {index < inlineLatex.length && (
                                <InlineMath math={inlineLatex[index]} />
                            )}
                        </React.Fragment>
                    ))}
                </>
            );
        }
        
        // Extract block LaTeX expressions
        const blockLatexMatches = content.match(blockLatexRegex) || [];
        const blockLatex = blockLatexMatches.map(match => match.slice(2, -2));
        
        // Render with block LaTeX
        return (
            <>
                {blockParts.map((part, index) => (
                    <React.Fragment key={index}>
                        {index > 0 && index <= blockLatex.length && (
                            <div className="my-2">
                                <BlockMath math={blockLatex[index - 1]} />
                            </div>
                        )}
                        {part && (
                            <div>{renderContent(part)}</div>
                        )}
                    </React.Fragment>
                ))}
            </>
        );
    };
    
    const openModal = (imageUrl: string) => {
        setSelectedImage(imageUrl);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };
    
    // Function to get the full image URL
    const getImageUrl = (url: string) => {
        if (url.startsWith('/images')) {
            return `${process.env.REACT_APP_BACKEND_URL}${url}`;
        }
        return url;
    };
    
    return (
        <Layout activeMenu='Mes Fiches' sidePannelView={null}>
            {/* Add the detail component conditionally */}
            {((data && showDetail)) && (
                <DetailFiche 
                    end={data?.currentStep === ficheSteps.length} 
                    fiche={data} 
                    onClose={() => setShowDetail(false)} 
                />
            )}
            
            <div className='flex flex-col gap-3 mb-8 mx-4 md:mx-0'>
                <div className="flex flex-row justify-between items-center">
                    <h1 className="font-bold text-xl md:text-2xl">Fiche : {data?.lessonTitle || 'Génération de fiche'}</h1>
                    
                </div>
                
                {/* Tags for metadata with updated styling */}
                {!isLoading && data && (
                    <div className="flex flex-wrap gap-2">
                        {data.subject && (
                            <div className="bg-primary50/10 text-black px-3 py-1 rounded-[8px] text-[12px]">
                                Cours : {data.subject}
                            </div>
                        )}
                        {data.classe && (
                            <div className="bg-primary50/10 text-black px-3 py-1 rounded-[8px] text-[12px]">
                                Niveau: { getClasse(data.classe)}
                            </div>
                        )}
                        {data.areaOfLife && (
                            <div className="bg-primary50/10 text-black px-3 py-1 rounded-[8px] text-[12px]">
                                Domaine : {data.areaOfLife}
                            </div>
                        )}
                    </div>
                )}
            </div>
            
            <div className='min-h-[100vh] w-full flex flex-col p-5 md:p-10 rounded-[10px] bg-[#e2fbfb] print:bg-white'>
                {isLoading ? (
                    <div className='flex flex-col items-center justify-center mt-16'>
                        <LoadingIcon />
                        <p className='mt-4 text-[14px] text-gray-700'>Chargement de votre fiche...</p>
                    </div>
                ) : (
                    <div className="flex flex-col space-y-6 mb-16 px-4">
                        
                    <div className="flex space-x-3">
                        {/* Add feedback button */}
                        {(data?.currentStep === ficheSteps.length) && (
                            <button 
                                onClick={() => setShowFeedback(true)}
                                className=" bg-amber-300 text-black py-2 px-4 rounded-md text-sm font-medium"
                            >
                                Feedback
                            </button>
                        )}
                        {/* Add preview and print buttons */}
                        {(!isLoading && data && data.currentStep === ficheSteps.length && (!data?.imagePrompt || data?.images?.length)) && (
                            <button 
                                onClick={() => setShowDetail(true)} 
                                className='bg-[#0E9F90] px-3 py-0 text-[#fff] text-[14px] h-[35px] rounded-md flex items-center'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2z" />
                                </svg>
                                Télécharger
                            </button>
                        )}

                        {isLoading || (data?.currentStep !== ficheSteps.length || (data?.imagePrompt && !data?.images?.length)) && (
                            <button 
                                onClick={() => setShowDetail(true)} 
                                className='bg-[#0E9F90] px-3 py-0 text-[#fff] text-[14px] h-[35px] rounded-md flex items-center'
                            >
                                Aperçu 
                            </button>
                        )}

                        {/* add "en cours de génération" if the fiche is not completed and if imageprompt is not null, ensure images is not null*/}
                        {(isLoading || data?.currentStep !== ficheSteps.length || (data?.imagePrompt && !data?.images?.length)) && (
                            <div className="text-[14px] text-gray-500 bg-google-logo-yellow/20 px-3 py-0 rounded-md flex items-center">
                                En cours...
                            </div>
                        )}
                    </div>
                        {/* Add horizontal image display */}
                        {(data?.images || data?.imagePrompt) && (
                            <div className="mt-4 flex flex-row gap-4 overflow-x-auto pb-2">
                                {data?.images ? (
                                    // Existing images
                                    data.images.map((image, idx) => (
                                        <img
                                            key={idx}
                                            src={getImageUrl(image.url)}
                                            alt={`Generated image ${idx + 1}`}
                                            className="w-24 h-24 border-primary-400 border-[1px] object-cover rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
                                            onClick={() => openModal(getImageUrl(image.url))}
                                        />
                                    ))
                                ) : data?.imagePrompt ? (
                                    // Loading placeholders
                                    Array(2).fill(null).map((_, idx) => (
                                        <div 
                                            key={idx}
                                            className="w-24 h-24 border-primary-400 border-[1px] rounded-lg bg-gray-100 animate-pulse flex items-center justify-center"
                                        >
                                            <svg 
                                                className="w-8 h-8 text-gray-300 animate-spin" 
                                                fill="none" 
                                                viewBox="0 0 24 24"
                                            >
                                                <circle 
                                                    className="opacity-25" 
                                                    cx="12" 
                                                    cy="12" 
                                                    r="10" 
                                                    stroke="currentColor" 
                                                    strokeWidth="4"
                                                />
                                                <path 
                                                    className="opacity-75" 
                                                    fill="currentColor" 
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                />
                                            </svg>
                                        </div>
                                    ))
                                ) : null}
                            </div>
                        )}
                        
                        {/* Steps timeline with collapsible content */}
                        {ficheSteps.map((step, index) => {
                            const stepNumber = index + 1;
                            const status = stepStatus[stepNumber] || 'pending';
                            const stepContent = removemd(stepData[stepNumber] || '');
                            const isImageStep = hasImages(stepNumber);
                            const isExpanded = expandedSteps[stepNumber];
                            
                            // Status text
                            let statusText = 'en attente';
                            if (status === 'completed') {
                                statusText = 'généré(e) avec succès';
                            } else if (status === 'in-progress') {
                                statusText = 'en cours de génération';
                            }
                            
                            return (
                                <div 
                                    key={step.slug} 
                                    className="transition-all duration-300"
                                    ref={el => stepRefs.current[stepNumber] = el}
                                >
                                    <div 
                                        className={`flex items-center cursor-pointer`}
                                        onClick={() => toggleStepExpansion(stepNumber)}
                                    >
                                        <div className="mr-3">
                                            {renderStepIcon(status, stepNumber)}
                                        </div>
                                        <div className="flex-1">
                                            <h3 className="font-medium text-[16px]">{step.title}</h3>
                                            <p className="text-[14px] text-gray-600">{statusText}</p>
                                        </div>
                                        <div>
                                            <svg 
                                                xmlns="http://www.w3.org/2000/svg" 
                                                className={`h-5 w-5 transition-transform ${isExpanded ? 'rotate-180' : ''}`} 
                                                fill="none" 
                                                viewBox="0 0 24 24" 
                                                stroke="currentColor"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </div>
                                    </div>
                                    
                                    {isExpanded && (
                                        <div className="mt-3 ml-9 transition-all duration-300">
                                            {status === 'completed' ? (
                                                <div className="bg-primary50/10 rounded-lg p-4 shadow-sm">
                                                    {isImageStep && data?.images ? (
                                                        <div className="grid grid-cols-2 gap-4 mb-4">
                                                            {data.images.map((image, idx) => (
                                                                <img 
                                                                    key={idx}
                                                                    src={getImageUrl(image.url)}
                                                                    alt={`Generated image ${idx + 1}`}
                                                                    className="w-full h-auto rounded-lg cursor-pointer hover:opacity-90 transition-opacity"
                                                                    onClick={() => openModal(getImageUrl(image.url))}
                                                                />
                                                            ))}
                                                        </div>
                                                    ) : isImageStep && imageStatus === 'generating' ? (
                                                        <div className="flex items-center justify-center h-32 bg-gray-100 rounded-lg mb-4">
                                                            <div className="text-center">
                                                                <LoadingIcon />
                                                                <p className="mt-2 text-sm text-gray-600">Génération des images...</p>
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                    
                                                    <div className="prose prose-sm max-w-none">
                                                        {renderContent(stepData[stepNumber])}
                                                    </div>
                                                </div>
                                            ) : status === 'in-progress' ? (
                                                <div className="bg-primary50/10 rounded-lg p-4 shadow-sm flex items-center justify-center h-32">
                                                    <div className="text-center">
                                                        <LoadingIcon />
                                                        <p className="mt-2 text-sm text-gray-600">Génération en cours...</p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="bg-primary50/10 rounded-lg p-4 shadow-sm flex items-center justify-center h-24">
                                                    <p className="text-sm text-gray-500">Cette étape est en attente de génération</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Image Modal"
                className="m-4 max-w-4xl max-h-[90vh] outline-none"
                overlayClassName="fixed inset-0 bg-black/75 flex items-center justify-center backdrop-blur-sm"
            >
                {selectedImage && (
                    <div className="relative">
                        <img 
                            src={selectedImage} 
                            alt="Large view" 
                            className="max-w-full max-h-[85vh] rounded-xl shadow-2xl" 
                        />
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2 hover:bg-black/75 transition-colors"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                )}
            </Modal>
            
            {/* Add print styles */}
            <style>{`
                @media print {
                    body * {
                        visibility: hidden;
                    }
                    .layout-content, .layout-content * {
                        visibility: visible;
                    }
                    .print-hide {
                        display: none !important;
                    }
                    .print-show {
                        display: block !important;
                    }
                }
            `}</style>

            {/* Add feedback dialog */}
            <FeedbackDialog
                isOpen={showFeedback}
                onClose={() => setShowFeedback(false)}
                ficheId={id}
                initialPhoneNumber={profile?.phoneNumber}
            />
        </Layout>
    );
}; 