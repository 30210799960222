import { FC } from "react"
import { Fiche, getImageUrl, useFicheMutation } from "./hook"
import { useNavigate } from "react-router-dom";
import { extractSearchTerm, regexMaterial } from "../../../utils/image-processing";
import { LoadingIcon } from "../../../components/loader";
import removeMd from 'remove-markdown';

type DetailFicheProps = {
    onClose: () => void;
    fiche: Fiche;
    end?: boolean;
}

export const DetailFicheMaternelle: FC<DetailFicheProps> = ({fiche, onClose, end}) => {
    Object.keys(fiche).forEach((key) => {
        const theKey = key as keyof Fiche;
        if (typeof fiche[theKey] === 'string') {
            // @ts-ignore
            fiche[theKey] = removeMd(fiche[theKey])?.replaceAll?.('\\', '');
        }
    });
    const navigation = useNavigate();

    const mutationDocx = useFicheMutation();
    const generateDocument = () => {
        mutationDocx.mutate(fiche.id);
    };
      
    return (
        <div className="bg-[#000]/20 fixed w-full h-full top-0 left-0 p-5 md:p-10 z-[90000] overflow-y-scroll">
            <div className="bottom-5 fixed items-center justify-center gap-4 flex flex-row w-[100vw]">
                {!end && <button onClick={() => onClose?.()} className="bg-[#ff0000] rounded-[5px] px-4 py-1 text-[#fff]">Fermer l'apercu</button>}
                {end && (
                    <>
                    <button onClick={() => onClose?.()} className="bg-[#ff0000] rounded-[5px] px-4 py-1 text-[#fff]">Fermer</button>
                <button onClick={generateDocument} className="bg-[#0E9F90] flex flex-row gap-3 rounded-[5px] px-4 py-1 text-[#fff] ml-5 shadow-lg shadow-[#0E9F90]">Enregistrer {mutationDocx.isLoading && <LoadingIcon height={'h-[15px] w-[15px]'} />}</button>
                </>
                )}
            </div>
            <div className="bg-[#fff] w-full md:w-3/4 p-5 mx-auto">
                <div className="border-b-[1px] border-b-primary50 pb-5 whitespace-pre-wrap">
                        <h1 className="text-[16px] font-bold mb-2">COMPETENCES</h1>
                        Après avoir réalisé l'ensemble des activités proposées, l'élève sera capable de traiter avec succès et de manière acceptable des situations faisant appel à des savoirs essentiels de la catégorie <b>" {fiche.lessonTitle} "</b>.
                </div>
                <div className="border-b-[1px] border-b-primary50 pb-5 whitespace-pre-wrap">
                    <h1 className="text-[16px] font-bold mb-2">SITUATION</h1>
                    {fiche.situation}
                </div>
                <div className="gap-5 flex flex-col p-2 md:p-5 border-[#000] border-[1px]">
                    <h1 className="text-[16px] font-bold mb-2">ACTIVITE INITIALE</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'enseignant</h1>
                            <h2 className="font-bold text-[15px] mb-2">Rappel</h2>
                            {fiche.revisionTeacherRappel?.trimStart?.()}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className="whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'eleve</h1>
                            <br />
                            {fiche.revisionStudentRappel?.trimStart?.()}
                        </div>
                    </div>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h2 className="font-bold text-[15px] mb-2">Motivation</h2>
                            <p>{extractSearchTerm(fiche.revisionTeacherMotivation?.trimStart?.(), regexMaterial)}</p>
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className="whitespace-pre-wrap w-full md:w-[49%]">
                            {fiche.images?.map((image) => (
                                <img key={getImageUrl(image.url)} src={getImageUrl(image.url)} alt={image.alt} className="mr-3 cursor-pointer inline-block object-cover rounded-sm h-[80px] w-[80px] z-10" />
                            ))}
                            <br />
                            {fiche.revisionStudentMotivation?.trimStart?.()}
                        </div>
                    </div>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h2 className="font-bold text-[15px] mb-2">Annonce du sujet</h2>
                            
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className="whitespace-pre-wrap w-full md:w-[49%]">
                            <h2 className="font-bold text-[15px] mb-2">Rappel</h2>
                            {fiche.lessonTitle}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">ACTIVITES PRINCIPALES</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'instituteur/trice</h1>
                            {fiche.activitePrincipaleTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités des enfants</h1>
                            {fiche.activitePrincipaleStudent}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">SYNTHESE</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'instituteur/trice</h1>
                            {fiche.syntheseTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap md:w-[49%] w-full">
                            <h1 className="text-[16px] font-bold mb-2">Activités des enfants</h1>
                            {fiche.syntheseStudent}
                        </div>
                    </div>
                    <h1 className="text-[16px] font-bold mb-2">ACTIVITES DE CONTROLE</h1>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h1 className="text-[16px] font-bold mb-2">Activités de l'instituteur/trice</h1>
                            <h2 className="font-bold text-[15px] mb-2">Application</h2>
                            {fiche.activiteControleApplicationTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap md:w-[49%] w-full">
                            <h1 className="text-[16px] font-bold mb-2">Activités des enfants</h1>
                            <h2 className="font-bold text-[15px] mb-2">Application</h2>
                            {fiche.activiteControleApplicationStudent}
                        </div>
                    </div>
                    <div className="border-b-[1px] border-b-primary50 pb-5 flex md:flex-row flex-col md:justify-between justify-normal">
                        <div className=" whitespace-pre-wrap w-full md:w-[49%]">
                            <h2 className="font-bold text-[15px] mb-2">Evaluation</h2>
                            {fiche.activiteControleEvaluationTeacher}
                        </div>
                        <div className="md:w-[1px] w-full md:h-full  h-[1px] bg-primary50 my-5 md:my-0" />
                        <div className=" whitespace-pre-wrap md:w-[49%] w-full">
                            <h2 className="font-bold text-[15px] mb-2">Evaluation</h2>
                            {fiche.activiteControleEvaluationStudent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}